import { FC, useMemo, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  ChevronRightIcon,
  Icon,
  Section,
  SingleToggleCard,
  Tag,
} from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api-core';

import { Alert } from '../../../../../app/components/Alert';
import { formatDateToInternationalFormat } from '../../../../../app/utils/constants/defaults';
import { useOnrampProvidersContext } from '../../../../../app/context/OnrampProvidersContext';
import {
  AdminIcon,
  BanxaFilledIcon,
  CheckCircleIcon,
} from '../../../../../icons';
import { SideDrawer } from '../../../../components/SideDrawer';

export const OnrampProviders: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { providers, createProvider, toggleProvider } =
    useOnrampProvidersContext();

  const [showBanxaDrawer, setShowBanxaDrawer] = useState(false);

  const banxaProvider = useMemo(
    () =>
      providers.find((provider) => provider.provider === ProviderEnum.Banxa),
    [providers],
  );

  const [banxaEnabled, setBanxaEnabled] = useState(
    Boolean(banxaProvider?.enabledAt) ?? false,
  );

  const banxaToggleChecked = Boolean(banxaProvider?.enabledAt) || banxaEnabled;

  const acceptedTerms = async () => {
    setLoading(true);

    await createProvider(
      {
        provider: ProviderEnum.Banxa,
        termsUrl:
          'https://banxa.com/wp-content/uploads/2023/10/Banxa-Customer-Terms-and-Conditions-30-October-2023-BANXA.pdf',
      },
      {
        onSettled: async (provider) => {
          setLoading(false);

          if (!provider) {
            return;
          }

          await toggleProvider(provider, {
            onSettled: () => setLoading(false),
          });
        },
      },
    );
  };

  const toggleBanxa = async () => {
    if (!banxaProvider) {
      setBanxaEnabled(true);
      return;
    }

    await toggleProvider(banxaProvider, {
      onSettled: () => setLoading(false),
    });

    setBanxaEnabled(!banxaToggleChecked);
  };

  const needsConfiguration =
    banxaEnabled && !banxaProvider?.termsAcceptedByUser;

  const toggleRightSideBanners = useMemo(() => {
    const banners = [];

    if (needsConfiguration) {
      banners.push({
        Tag: (
          <Tag
            icon={<ChevronRightIcon />}
            iconPosition='right'
            text={t('common.tags.needs_configuration')}
            color='gray'
            size='large'
          />
        ),
        id: 'needsConfiguration',
      });
    }

    return banners;
  }, [needsConfiguration, t]);

  return (
    <div>
      <Section
        title={t('v2.page.funding.sections.funding_providers.title')}
        description={t(
          'v2.page.funding.sections.funding_providers.description',
        )}
      >
        <SingleToggleCard
          accordionKey='banxa-enabled'
          title={t(
            'v2.page.funding.sections.funding_providers.providers.banxa.title',
          )}
          allowExpand={false}
          data-testid='banxa-toggle'
          Icon={<BanxaFilledIcon />}
          onCardClick={() => setShowBanxaDrawer(true)}
          customActionIcon={{
            Icon: <Icon icon={<AdminIcon />} color='primary-1' />,
          }}
          RightSideTags={toggleRightSideBanners}
          inputProps={{
            checked: banxaToggleChecked ?? false,
            id: 'banxa-enabled',
            isSaved: true,
            onChange: toggleBanxa,
            type: 'toggle',
          }}
        />
      </Section>
      <SideDrawer
        handleClose={() => setShowBanxaDrawer(false)}
        isOpen={showBanxaDrawer}
        title='Banxa'
        Icon={<BanxaFilledIcon />}
        docs={{
          text: 'Learn more',
          url: 'https://docs.dynamic.xyz/fiat-onboarding/banxa#banxa',
        }}
      >
        <div className='flex flex-col gap-4'>
          <ul className='list-disc pl-6 text-gray-600 marker:text-gray-600'>
            <li>
              Agree to Banxa&apos;s{' '}
              <a
                href='https://banxa.com/wp-content/uploads/2023/10/Banxa-Customer-Terms-and-Conditions-30-October-2023-BANXA.pdf'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-600 hover:text-blue-800'
              >
                Terms and Conditions
              </a>
            </li>
            <li>
              Confirm that you won&apos;t use Banxa for prohibited activities
            </li>
          </ul>
          <div className='gap-6'>
            {banxaProvider?.termsAcceptedByUser ? (
              <Alert severity='info' className='flex flex-row gap-2'>
                <CheckCircleIcon />
                <Trans i18nKey='v2.page.funding.sections.funding_providers.providers.banxa.terms.signed_message'>
                  {banxaProvider.termsAcceptedByUser.email}
                </Trans>
                <div className='flex flex-row justify-end flex-grow'>
                  {formatDateToInternationalFormat(
                    banxaProvider.termsAcceptedByUser.createdAt,
                  )}
                </div>
              </Alert>
            ) : (
              <Button
                dataTestId='sign_term_button'
                variant='primary'
                type='button'
                disabled={loading}
                onClick={acceptedTerms}
                text={t('integrations.wallets.onramp.terms.sign_button')}
              />
            )}
          </div>
        </div>
      </SideDrawer>
    </div>
  );
};
