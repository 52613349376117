import { FC, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';

import { Section } from '@dynamic-labs/northstar';

import { logger } from '../../../../app/services/logger';
import { useNameServicesContext } from '../../../../app/context/NameServicesContext';
import { SaveBanner } from '../../../components';
import styles from '../GlobalWallets.module.scss';

import { SubdomainsToggle } from './SubdomainsToggle';
import { SubdomainsRequiredToggle } from './SubdomainsRequiredToggle';
import { CustomAddressFieldValues, DOCS_URL } from './util';

export const CustomAddressSection: FC = () => {
  const { t } = useTranslation();

  const { nameServices, enableNameServices, disableNameServices } =
    useNameServicesContext();

  const formikRef = useRef<FormikProps<CustomAddressFieldValues>>(null);

  const [loading, setLoading] = useState(false);

  const isSubdomainsEnabled = useMemo(
    () => nameServices !== undefined && nameServices.enabledAt !== undefined,
    [nameServices],
  );

  const initialValues: CustomAddressFieldValues = useMemo(
    () => ({
      subdomainsEnabled: isSubdomainsEnabled,
    }),
    [isSubdomainsEnabled],
  );

  const onSubmit = async (values: CustomAddressFieldValues) => {
    try {
      setLoading(true);
      if (values.subdomainsEnabled) {
        await enableNameServices();
      } else {
        await disableNameServices();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
  };

  return (
    <Section
      title={t('v2.page.global_wallets.subdomains.title')}
      description={t('v2.page.global_wallets.subdomains.description')}
      help={{
        text: t('v2.page.global_wallets.subdomains.learn_more'),
        url: DOCS_URL,
      }}
      alerts={[
        {
          description: t('v2.page.global_wallets.subdomains.alert.description'),
          title: t('v2.page.global_wallets.subdomains.alert.title'),
          variant: 'warning',
        },
      ]}
      alertsAlignment='top'
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <div className={styles.wrapper}>
              <SubdomainsToggle isNameServicesEnabled={Boolean(nameServices)} />
            </div>
            {isSubdomainsEnabled && (
              <div className={styles.wrapper}>
                <SubdomainsRequiredToggle />
              </div>
            )}
            <AnimatePresence>
              {values.subdomainsEnabled !== initialValues.subdomainsEnabled && (
                <SaveBanner
                  handleOnSave={handleSubmit}
                  handleOnCancel={handleCancelClick}
                  isLoading={loading}
                />
              )}
            </AnimatePresence>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
