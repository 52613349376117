/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GlobalWalletSettingsUpdateRequest
 */
export interface GlobalWalletSettingsUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsUpdateRequest
     */
    walletName?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsUpdateRequest
     */
    popupPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsUpdateRequest
     */
    walletIconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalWalletSettingsUpdateRequest
     */
    customCssUrl?: string;
}

export function GlobalWalletSettingsUpdateRequestFromJSON(json: any): GlobalWalletSettingsUpdateRequest {
    return GlobalWalletSettingsUpdateRequestFromJSONTyped(json, false);
}

export function GlobalWalletSettingsUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalWalletSettingsUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletName': !exists(json, 'walletName') ? undefined : json['walletName'],
        'popupPageTitle': !exists(json, 'popupPageTitle') ? undefined : json['popupPageTitle'],
        'walletIconUrl': !exists(json, 'walletIconUrl') ? undefined : json['walletIconUrl'],
        'customCssUrl': !exists(json, 'customCssUrl') ? undefined : json['customCssUrl'],
    };
}

export function GlobalWalletSettingsUpdateRequestToJSON(value?: GlobalWalletSettingsUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletName': value.walletName,
        'popupPageTitle': value.popupPageTitle,
        'walletIconUrl': value.walletIconUrl,
        'customCssUrl': value.customCssUrl,
    };
}

