/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GetAccountBalancesNetworkIdEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_10 = 10,
    NUMBER_137 = 137,
    NUMBER_42161 = 42161,
    NUMBER_8453 = 8453
}

export function GetAccountBalancesNetworkIdEnumFromJSON(json: any): GetAccountBalancesNetworkIdEnum {
    return GetAccountBalancesNetworkIdEnumFromJSONTyped(json, false);
}

export function GetAccountBalancesNetworkIdEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAccountBalancesNetworkIdEnum {
    return json as GetAccountBalancesNetworkIdEnum;
}

export function GetAccountBalancesNetworkIdEnumToJSON(value?: GetAccountBalancesNetworkIdEnum | null): any {
    return value as any;
}

