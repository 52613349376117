import { t } from 'i18next';

import {
  type SectionProps,
  OverviewIcon,
  UsersIcon,
  AnalyticsIcon,
  DevelopersIcon,
  SettingsIcon,
  type SectionItem,
  type SectionSubItem,
  DesignIcon,
  WalletLinearIcon,
  LogInMethodsIcon,
  ChainAndNetworksIcon,
  GlobeIcon,
  CoinsIcon,
  ShieldCheckIcon,
} from '@dynamic-labs/northstar';
import { EnvironmentEnum, RoleEnum } from '@dynamic-labs/sdk-api';

import { ROUTES } from '../../../app/components/Navigation/data';
import { AdminIcon, ConfigurationsIcon, LockIcon } from '../../../icons'; // TODO MOVE TO NS ICONS

/** Type for checking access to a [NavigationItem] */
export type NavigationItemPermissions = {
  environment?: EnvironmentEnum;
  hideBehindFeatureFlagKey?: string;
  minRole: RoleEnum;
};

type NavigationItem = Omit<SectionProps, 'items'> & {
  items: (Omit<SectionItem, 'subItems'> &
    NavigationItemPermissions & {
      subItems?: (Omit<SectionSubItem, 'disabled'> &
        NavigationItemPermissions)[];
    })[];
};

export const navigationItems: Omit<NavigationItem, 'disabled'>[] = [
  {
    items: [
      {
        icon: <OverviewIcon />,
        linkTo: ROUTES.overview,
        minRole: RoleEnum.Viewer,
        text: t('v2.navigation.sections.my_workspace.items.overview.title'),
      },
      {
        icon: <UsersIcon />,
        linkTo: ROUTES.users,
        minRole: RoleEnum.Viewer,
        subItems: [
          {
            linkTo: ROUTES.usersAuthenticated,
            minRole: RoleEnum.Viewer,
            text: t(
              'v2.navigation.sections.my_workspace.items.users_management.items.users',
            ),
          },
          {
            linkTo: ROUTES.usersVisitors,
            minRole: RoleEnum.Viewer,
            text: t(
              'v2.navigation.sections.my_workspace.items.users_management.items.visitors',
            ),
          },
        ],
        text: t(
          'v2.navigation.sections.my_workspace.items.users_management.title',
        ),
      },
      {
        icon: <AnalyticsIcon />,
        linkTo: ROUTES.analytics,
        minRole: RoleEnum.Viewer,
        text: t('v2.navigation.sections.my_workspace.items.analytics.title'),
      },
      {
        icon: <DevelopersIcon />,
        linkTo: ROUTES.developer,
        minRole: RoleEnum.Developer,
        subItems: [
          {
            linkTo: ROUTES.developerApi,
            minRole: RoleEnum.Developer,
            text: t(
              'v2.navigation.sections.my_workspace.items.developers.items.sdk_api',
            ),
          },
          {
            linkTo: ROUTES.developerWebhooks,
            minRole: RoleEnum.Developer,
            text: t(
              'v2.navigation.sections.my_workspace.items.developers.items.webhooks',
            ),
          },
          {
            linkTo: ROUTES.developerThirdPartyAuth,
            minRole: RoleEnum.Developer,
            text: t(
              'v2.navigation.sections.my_workspace.items.developers.items.third_party_auth',
            ),
          },
          {
            linkTo: ROUTES.developerDomains,
            minRole: RoleEnum.Developer,
            text: t(
              'v2.navigation.sections.my_workspace.items.developers.items.domains',
            ),
          },
          {
            environment: EnvironmentEnum.Sandbox,
            linkTo: ROUTES.developerTestAccounts,
            minRole: RoleEnum.Developer,
            text: t(
              'v2.navigation.sections.my_workspace.items.developers.items.test_accounts',
            ),
          },
        ],
        text: t('v2.navigation.sections.my_workspace.items.developers.title'),
      },
    ],
    title: t('v2.navigation.sections.my_workspace.title'),
  },
  {
    items: [
      {
        icon: <LogInMethodsIcon />,
        linkTo: ROUTES.logInMethods,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.authentication.items.log_in_methods.title',
        ),
      },
      {
        icon: <WalletLinearIcon />,
        linkTo: ROUTES.embeddedWalletsDynamic,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.authentication.items.embedded_wallets.title',
        ),
      },
      {
        icon: <ChainAndNetworksIcon />,
        linkTo: ROUTES.chainsAndNetworks,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.authentication.items.chains_networks.title',
        ),
      },
      {
        hideBehindFeatureFlagKey: 'enableEnsSubdomains',
        icon: <GlobeIcon />,
        linkTo: ROUTES.globalWallets,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.authentication.items.global_wallets.title',
        ),
      },
      {
        icon: <ConfigurationsIcon />,
        linkTo: ROUTES.configurations,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.authentication.items.configurations.title',
        ),
      },
    ],
    title: t('v2.navigation.sections.authentication.title'),
  },
  {
    items: [
      {
        icon: <LockIcon />,
        linkTo: ROUTES.security,
        minRole: RoleEnum.Developer,
        text: t('v2.navigation.sections.security.items.account_security.title'),
      },
      {
        icon: <ShieldCheckIcon />,
        linkTo: ROUTES.fraudPrevention,
        minRole: RoleEnum.Developer,
        text: t('v2.navigation.sections.security.items.fraud_prevention.title'),
      },
    ],
    title: t('v2.navigation.sections.security.title'),
  },
  {
    items: [
      {
        hideBehindFeatureFlagKey: 'enableExternalWalletFunding',
        icon: <CoinsIcon />,
        linkTo: ROUTES.funding,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.post_authentication.items.funding.title',
        ),
      },
    ],
    title: t('v2.navigation.sections.post_authentication.title'),
  },
  {
    items: [
      {
        icon: <SettingsIcon />,
        linkTo: ROUTES.settingsGeneral,
        minRole: RoleEnum.Developer,
        text: t(
          'v2.navigation.sections.customizations.items.settings.items.general',
        ),
      },
      {
        icon: <DesignIcon />,
        linkTo: ROUTES.design,
        minRole: RoleEnum.Developer,
        text: t('v2.navigation.sections.customizations.items.design.title'),
      },
    ],
    title: t('v2.navigation.sections.customizations.items.settings.title'),
  },
  {
    items: [
      {
        icon: <AdminIcon />,
        linkTo: ROUTES.admin,
        minRole: RoleEnum.Admin,
        text: t('v2.navigation.sections.admin.items.admin.title'),
      },
    ],
    title: t('v2.navigation.sections.admin.title'),
  },
];
