import { FC } from 'react';

import { useNameServicesContext } from '../../../../../app/context/NameServicesContext';

import styles from './SubdomainsDrawer.module.scss';
import { SetUpEnsSubdomains } from './SetUpEnsSubdomains';
import { ExistingSubdomains } from './ExistingSubdomains';

/** Wrapper component for managing subdomains */
export const SubdomainsDrawer: FC = () => {
  const { nameServices, isLoadingNameServices } = useNameServicesContext();

  return (
    <div className={styles.content}>
      {isLoadingNameServices && <p>Loading...</p>}
      {!isLoadingNameServices && nameServices && <ExistingSubdomains />}
      {!isLoadingNameServices && !nameServices && <SetUpEnsSubdomains />}
    </div>
  );
};
