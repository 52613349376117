import { FC, useState } from 'react';

import { Field, Form, Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  CustomHostnameUsageEnum,
  UnprocessableEntityErrorCode,
} from '@dynamic-labs/sdk-api';
import { Button, Input } from '@dynamic-labs/northstar';
import { useCreateCustomHostname } from '@dynamic-labs/redcoast-query';

import { useEnvironmentId } from '../../../../../app/routes/Configurations/Providers/hooks';

import styles from './AddDomain.module.scss';

type AddDomainProps = {
  disabled?: boolean;
  domainUsage: CustomHostnameUsageEnum;
  refetch: () => void;
};

const domainRegexp = /^(?!-)[A-Za-z0-9-]+([-.][a-z0-9]+)*\.[A-Za-z]{2,15}$/;

export const AddDomain: FC<AddDomainProps> = ({
  domainUsage,
  refetch,
  disabled,
}) => {
  const { t } = useTranslation();

  const environmentId = useEnvironmentId();

  const [error, setError] = useState<string | undefined>();

  const {
    mutate: createCustomHostnameMutation,
    isLoading: isLoadingCreateCustomHostname,
  } = useCreateCustomHostname({
    onError: async (apiError) => {
      try {
        const jsonError = await apiError.json();

        const isInvalidCustomHostnameError =
          jsonError.error &&
          'code' in jsonError &&
          jsonError.code === UnprocessableEntityErrorCode.InvalidCustomHostname;

        if (isInvalidCustomHostnameError) {
          setError(jsonError.error);
        }
      } catch {
        setError(undefined);
      }
    },
    onSuccess: () => {
      refetch();
    },
  });

  const handleAddDomain = (values: FormikValues) => {
    setError(undefined);

    const hostname = values.hostname.trim();

    const validDomain = domainRegexp.test(hostname);

    if (!validDomain) {
      setError(t('domains.add_domain.input_error'));
      return;
    }

    createCustomHostnameMutation({
      customHostnameCreateRequest: {
        hostname,
        usage: domainUsage,
      },
      environmentId: environmentId || '',
    });
  };

  return (
    <Formik
      onSubmit={handleAddDomain}
      initialValues={{ hostname: '' }}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form className={styles.form}>
          <Field
            as={Input}
            className={styles.input}
            name='hostname'
            id={`input-${domainUsage}`}
            label={t('domains.add_domain.field_label')}
            placeholder={t('domains.add_domain.field_placeholder')}
            error={error}
            errorText={error}
            disabled={isLoadingCreateCustomHostname || disabled}
          />
          <Button
            text={t('domains.add_domain.button_label')}
            onClick={handleSubmit}
            variant='secondary'
            size='large'
            isLoading={isLoadingCreateCustomHostname}
            disabled={disabled}
          />
        </Form>
      )}
    </Formik>
  );
};
