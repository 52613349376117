import type { Network } from '@dynamic-labs/sdk-api';

const NETWORK_PRIORITY: Record<string, number> = {
  'Ethereum Mainnet': 0,
  Sepolia: 1,
};

const sortNetworks = (networksToSort: Network[]) =>
  [...networksToSort].sort((a, b) => {
    // First check priority networks
    const priorityA = NETWORK_PRIORITY[a.chainName] ?? Number.MAX_SAFE_INTEGER;
    const priorityB = NETWORK_PRIORITY[b.chainName] ?? Number.MAX_SAFE_INTEGER;

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // Then sort by enabled status
    if (a.enabled !== b.enabled) {
      return a.enabled ? -1 : 1;
    }

    // Finally sort alphabetically within each group
    return a.chainName.localeCompare(b.chainName);
  });

export default sortNetworks;
