import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '@dynamic-labs/northstar';

import styles from './DeleteConfirmationDialog.module.scss';

type DeleteConfirmationDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
};

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onCancel} className={styles.dialog}>
      <Dialog.Header
        title={t('domains.domain_info.delete_confirmation.title')}
      />
      <Dialog.Content className={styles.content}>
        <Typography variant='paragraph-3' weight='regular' color='gray-1'>
          {t('domains.domain_info.delete_confirmation.description')}
        </Typography>
        <Typography variant='paragraph-3' weight='bold' color='gray-1'>
          {t('domains.domain_info.delete_confirmation.prompt')}
        </Typography>
        <div className={styles.buttons}>
          <Button
            text={t(
              'domains.domain_info.delete_confirmation.cancel_button_label',
            )}
            onClick={onCancel}
            variant='secondary'
            size='large'
            dataTestId='delete-domain-cancel-button'
          />
          <Button
            text={t(
              'domains.domain_info.delete_confirmation.confirm_button_label',
            )}
            onClick={onConfirm}
            variant='warning'
            size='large'
            dataTestId='delete-domain-confirm-button'
          />
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
