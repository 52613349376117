import type { FC } from 'react';
import { useEffect } from 'react';

// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  SingleToggleCard,
  Input,
  Tag,
  ChevronRightIcon,
} from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useModalHashLocation } from '../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../components/SideDrawer';
import { AdminIcon } from '../../../../../icons';

type HCaptchaSectionProps = {
  disabled?: boolean;
};

export const HCaptchaSection: FC<HCaptchaSectionProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const hCaptchaSettings = settings[activeEnvironmentType]?.security?.hCaptcha;
  const initialHCaptchaSettings =
    initialSettings[activeEnvironmentType]?.security?.hCaptcha;

  const {
    showModal: showHCaptchaModal,
    handleShowModal: handleShowHCaptchaModal,
  } = useModalHashLocation({
    currentHash: 'hcaptcha-settings',
  });

  const schema = yup.object({
    secretKey: yup
      .string()
      .required(
        t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.fields.required',
        ),
      ),
    siteKey: yup
      .string()
      .required(
        t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.fields.required',
        ),
      ),
  });

  type HCaptchaForm = yup.InferType<typeof schema>;

  const methods = useForm<HCaptchaForm>({
    defaultValues: {
      secretKey: hCaptchaSettings?.secretKey || '',
      siteKey: hCaptchaSettings?.siteKey || '',
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    register,
    formState: { errors },
    watch,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      secretKey: hCaptchaSettings?.secretKey || '',
      siteKey: hCaptchaSettings?.siteKey || '',
    });
  }, [hCaptchaSettings?.secretKey, hCaptchaSettings?.siteKey, reset]);

  const handleHCaptchaToggle = () => {
    const newEnabled = !hCaptchaSettings?.enabled;
    setSettings((prevSettings) => {
      const newSettings = { ...prevSettings };
      if (newSettings[activeEnvironmentType]) {
        newSettings[activeEnvironmentType] = {
          ...newSettings[activeEnvironmentType],
          security: {
            ...newSettings[activeEnvironmentType]?.security,
            hCaptcha: {
              ...newSettings[activeEnvironmentType]?.security?.hCaptcha,
              enabled: newEnabled,
            },
          },
        };
      }
      return newSettings;
    });
  };

  const handleFormBlur = () => {
    const formValues = watch();
    setSettings((prevSettings) => ({
      ...prevSettings,
      [activeEnvironmentType]: {
        ...prevSettings[activeEnvironmentType],
        security: {
          ...prevSettings[activeEnvironmentType]?.security,
          hCaptcha: {
            ...prevSettings[activeEnvironmentType]?.security?.hCaptcha,
            secretKey: formValues.secretKey,
            siteKey: formValues.siteKey,
          },
        },
      },
    }));
  };

  const isHCaptchaEnabled = Boolean(hCaptchaSettings?.enabled);
  const isHCaptchaSaved =
    hCaptchaSettings?.enabled === initialHCaptchaSettings?.enabled;
  const needsConfiguration =
    isHCaptchaEnabled &&
    (!hCaptchaSettings?.siteKey || !hCaptchaSettings?.secretKey);

  const hCaptchaTags = needsConfiguration
    ? [
        {
          Tag: (
            <Tag
              icon={<ChevronRightIcon />}
              iconPosition='right'
              text={t(
                'v2.page.fraud_prevention.security_integrations.hcaptcha.tags.needs_configuration',
              )}
              color='gray'
              size='large'
            />
          ),
          id: 'needsConfiguration',
        },
      ]
    : [];

  return (
    <>
      <SingleToggleCard
        accordionKey='hcaptcha'
        title={t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.title',
        )}
        description={t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.description',
        )}
        allowExpand={false}
        onCardClick={() => handleShowHCaptchaModal(true)}
        inputProps={{
          checked: isHCaptchaEnabled,
          disabled,
          id: 'hcaptcha-toggle',
          isSaved: isHCaptchaSaved,
          isWarning: needsConfiguration,
          onChange: handleHCaptchaToggle,
          type: 'toggle',
        }}
        RightSideTags={hCaptchaTags}
        customActionIcon={{
          Icon: <AdminIcon data-testid='admin-icon' />,
        }}
      />

      <SideDrawer
        handleClose={() => handleShowHCaptchaModal(false)}
        isOpen={showHCaptchaModal}
        title={t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.title',
        )}
        description={t(
          'v2.page.fraud_prevention.security_integrations.hcaptcha.description',
        )}
        docs={{
          text: t(
            'v2.page.fraud_prevention.security_integrations.hcaptcha.docs',
          ),
          url: 'https://docs.dynamic.xyz/docs/hcaptcha',
        }}
      >
        <div>
          <form
            onBlur={handleFormBlur}
            className='mt-4 space-y-4'
            data-testid='hcaptcha-form'
          >
            <Input
              name='siteKey'
              error={!!errors.siteKey}
              errorText={errors.siteKey?.message}
              label={t(
                'v2.page.fraud_prevention.security_integrations.hcaptcha.siteKey.label',
              )}
              placeholder={t(
                'v2.page.fraud_prevention.security_integrations.hcaptcha.siteKey.placeholder',
              )}
              onBlur={register('siteKey').onBlur}
              onChange={register('siteKey').onChange}
              ref={register('siteKey').ref}
            />
            <Input
              name='secretKey'
              error={!!errors.secretKey}
              errorText={errors.secretKey?.message}
              label={t(
                'v2.page.fraud_prevention.security_integrations.hcaptcha.secretKey.label',
              )}
              placeholder={t(
                'v2.page.fraud_prevention.security_integrations.hcaptcha.secretKey.placeholder',
              )}
              onBlur={register('secretKey').onBlur}
              onChange={register('secretKey').onChange}
              ref={register('secretKey').ref}
            />
          </form>
        </div>
      </SideDrawer>
    </>
  );
};
