import { useTranslation } from 'react-i18next';

import { ExportModelEnum } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import Portal from '../../../../components/Portal';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import Button from '../../../../components/Button';
import { useExportsColumns } from '../helpers';
import ReactTable from '../../../../components/ReactTable';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { Icon } from '../../../../components/Icon';
import { ExportIcon, PlusIcon } from '../../../../../icons';

import styles from './exportsModal.module.css';
import { useExportsData } from './useExportsData';

type Props = {
  model: ExportModelEnum;
  setShowExportsModal: (show: boolean) => void;
};

export const ExportsModal = ({ setShowExportsModal, model }: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const { t } = useTranslation();
  const { columns } = useExportsColumns();
  const {
    allExports,
    createExport,
    refetch,
    disableRefetchButton,
    disableAddButton,
  } = useExportsData(model);

  if (!activeEnvironment?.id) {
    logger.error('No active environment found');
    return null;
  }

  return (
    <Portal
      handleClose={() => setShowExportsModal(false)}
      className={styles.portal}
    >
      <SideModalHeader
        Icon={ExportIcon}
        title={t('users_export.title')}
        content={t('users_export.content')}
      />

      <div className={styles.buttons}>
        <Button
          variant='secondary'
          onClick={() => createExport()}
          disabled={disableAddButton}
          className={styles['button--add']}
          leading={<Icon size='medium' icon={<PlusIcon />} />}
        >
          {t('users_export.create_export')}
        </Button>
        <Button
          variant='secondary'
          onClick={() => refetch()}
          disabled={disableRefetchButton}
          className={styles['button--refresh']}
        >
          {t('users_export.refresh')}
        </Button>
      </div>

      <ReactTable columns={columns} data={allExports} />
    </Portal>
  );
};
