import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Section } from '@dynamic-labs/northstar';
import { useGetCustomHostnames } from '@dynamic-labs/redcoast-query';
import { CustomHostnameUsageEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentId } from '../../../../app/routes/Configurations/Providers/hooks';
import { DomainSection } from '../DomainSection';

export const Sections = () => {
  const { t } = useTranslation();

  const { enableCustomDomainDashboard, enableGlobalWalletPopup } = useFlags();

  const environmentId = useEnvironmentId();

  const {
    data: hostnames,
    refetch: refetchHostnames,
    isLoading: isLoadingHostnames,
    isRefetching: isRefetchingHostnames,
  } = useGetCustomHostnames(
    { environmentId: environmentId || '' },
    {
      enabled: !!environmentId,
    },
  );

  const cookiesHostname = useMemo(
    () =>
      hostnames?.customHostnames.find(
        (hostname) => hostname.usage === CustomHostnameUsageEnum.Cookies,
      ),
    [hostnames],
  );

  const globalWalletHostname = useMemo(
    () =>
      hostnames?.customHostnames.find(
        (hostname) => hostname.usage === CustomHostnameUsageEnum.GlobalWallet,
      ),
    [hostnames],
  );

  return (
    <>
      {enableCustomDomainDashboard && (
        <Section
          title={t('domains.cookies_section.title')}
          description={t('domains.cookies_section.description')}
        >
          <DomainSection
            hostname={cookiesHostname}
            refetch={refetchHostnames}
            domainUsage={CustomHostnameUsageEnum.Cookies}
            isLoading={isLoadingHostnames}
            isRefetching={isRefetchingHostnames}
          />
        </Section>
      )}
      {enableGlobalWalletPopup && (
        <Section
          title={t('domains.global_wallet_section.title')}
          description={t('domains.global_wallet_section.description')}
        >
          <DomainSection
            hostname={globalWalletHostname}
            refetch={refetchHostnames}
            domainUsage={CustomHostnameUsageEnum.GlobalWallet}
            isLoading={isLoadingHostnames}
            isRefetching={isRefetchingHostnames}
          />
        </Section>
      )}
    </>
  );
};
