import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseDeleteCustomHostnameHook } from './useDeleteCustomHostname.types';

export const useDeleteCustomHostname: UseDeleteCustomHostnameHook = (
  options,
) => {
  const { customHostnamesApi } = useRedcoastQueryContext();

  return useMutation(
    (deleteCustomHostnameRequest) =>
      customHostnamesApi.deleteCustomHostname(deleteCustomHostnameRequest),
    options,
  );
};
