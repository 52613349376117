import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PageHeader } from '../../components';

import { CustomAddressSection } from './CustomAddressSection';
import { GlobalWalletDomainSection } from './GlobalWalletDomainSection';
import styles from './GlobalWallets.module.scss';

export const GlobalWallets: FC = () => {
  const { t } = useTranslation();

  const { enableGlobalWalletPopup } = useFlags();

  return (
    <section className={styles.wrapper} data-testid='test-accounts'>
      <div className={styles.container}>
        <PageHeader
          title={t('v2.page.global_wallets.title')}
          description={t('v2.page.global_wallets.description')}
        />
        {enableGlobalWalletPopup && <GlobalWalletDomainSection />}
        <CustomAddressSection />
      </div>
    </section>
  );
};
