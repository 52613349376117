import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { CustomField, CustomFieldRequest } from '@dynamic-labs/sdk-api';

import { NAME_SERVICE_FIELD_KEY } from '../util';
import { useCustomFieldsContext } from '../../../../../app/context/CustomFieldsContext';

/** Toggle component to require users to set a subdomain */
export const SubdomainsRequiredToggle = () => {
  const { t } = useTranslation();

  const { updateCustomField, customFields } = useCustomFieldsContext();

  const nameServiceField = useMemo(
    () => customFields?.find((field) => field.key === NAME_SERVICE_FIELD_KEY),
    [customFields],
  );

  const handleToggleChange = useCallback(
    async (field: CustomField) => {
      field.required = !field.required;
      await updateCustomField(field.id, field as CustomFieldRequest);
    },
    [updateCustomField],
  );

  if (!nameServiceField) return null;

  return (
    <SingleToggleCard
      accordionKey='subdomains-required'
      title={t('v2.page.global_wallets.subdomains.required_toggle.title')}
      description={t(
        'v2.page.global_wallets.subdomains.required_toggle.description',
      )}
      allowExpand={false}
      inputProps={{
        checked: nameServiceField.required,
        id: 'subdomainsRequiredToggle',
        isSaved: true,
        onChange: () => handleToggleChange(nameServiceField),
        type: 'toggle',
      }}
    />
  );
};
