import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { EmbeddedWalletVersionEnum } from '@dynamic-labs/sdk-api';
import {
  OpacityAnimation,
  DropdownMenu,
  Typography,
} from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';

import styles from './DefaultWalletVersionDropdown.module.scss';

type DefaultWalletVersionDropdownProps = {
  disabled: boolean;
};

export const DefaultWalletVersionDropdown: FC<
  DefaultWalletVersionDropdownProps
> = ({ disabled }) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings } = useSettingsContext();

  const defaultWalletVersion =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.defaultWalletVersion;

  const handleDefaultWalletVersionChange = (version: string) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            defaultWalletVersion: version,
          },
        },
      },
    });
  };

  const walletVersions = Object.values(EmbeddedWalletVersionEnum);

  return (
    <AnimatePresence mode='wait'>
      <OpacityAnimation>
        <DropdownMenu
          value={defaultWalletVersion}
          onSelect={(value) => value && handleDefaultWalletVersionChange(value)} // Don't allow undefined value
          options={[
            ...walletVersions.map((version) => (
              <DropdownMenu.Item
                value={version}
                className={styles.item}
                rawOption={version}
                key={version}
              >
                <Typography
                  variant='paragraph-2'
                  weight='medium'
                  className={styles.text}
                >
                  {version}
                </Typography>
              </DropdownMenu.Item>
            )),
          ]}
          className={styles.container}
          allowUndefinedValue={false} // Don't allow undefined value
          itemsSideOffset={8}
          zIndex={21}
          disabled={disabled}
        />
      </OpacityAnimation>
    </AnimatePresence>
  );
};
