import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ClassStyleProps } from '../../utils/types';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { CopyIcon } from '../../icons';
import { Tooltip } from '../Tooltip';

import styles from './CopyField.module.scss';

export type CopyFieldProps = ClassStyleProps & {
  /** Uses custom children instead of the default text */
  children?: JSX.Element | JSX.Element[];

  /** The text to copy, regardless of children is set to */
  copyText: string;

  /** Customize the icon size (default 14) */
  iconSize?: number;

  /** Renders the copy button, defaults to true */
  showCopy?: boolean;
};

export const CopyField = ({
  children,
  className,
  copyText,
  iconSize = 14,
  showCopy = true,
}: CopyFieldProps) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyTextToClipboard = useCallback(() => {
    navigator.clipboard.writeText(copyText);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [copyText]);

  return (
    <div className={classNames(className, styles.wrapper)}>
      {children || (
        <Typography as='span' variant='paragraph-2'>
          {copyText}
        </Typography>
      )}

      {showCopy && (
        <Tooltip
          content={copied ? t('copy_button.copied') : t('copy_button.copy')}
        >
          <IconButton
            size={iconSize}
            icon={<CopyIcon />}
            onClick={copyTextToClipboard}
          />
        </Tooltip>
      )}
    </div>
  );
};
