import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useProvidersContext } from '../../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../../app/context/SettingsContext';

type ThirdPartyEmbeddedWalletToggleProps = {
  disabled: boolean;
};

export const ThirdPartyEmbeddedWalletToggle: FC<
  ThirdPartyEmbeddedWalletToggleProps
> = ({ disabled }) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isOnSignUpSettingSaved = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreation ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreation,
  );

  const isOnSignUpEnabled = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreation &&
      isTurnkeyEnabled &&
      !disabled,
  );

  const isEmbeddedWalletCreationForExternalEnabled = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreationForExternal &&
      isTurnkeyEnabled &&
      !disabled,
  );

  const isThirdPartyEmbeddedWalletSettingSaved = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreationForExternal ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreationForExternal,
  );

  const handleOnToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            automaticEmbeddedWalletCreationForExternal: event.target.checked,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.advanced_settings.embedded_wallet_for_third_party_wallets.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.advanced_settings.embedded_wallet_for_third_party_wallets.description',
      )}
      accordionKey='embedded_wallet_for_third_party_wallets'
      inputProps={{
        checked:
          isTurnkeyEnabled &&
          isOnSignUpEnabled &&
          isEmbeddedWalletCreationForExternalEnabled,
        disabled: !isTurnkeyEnabled || !isOnSignUpEnabled || disabled,
        id: 'embedded_wallet_for_third_party_wallets',
        isSaved:
          isTurnkeySettingSaved &&
          isOnSignUpSettingSaved &&
          isThirdPartyEmbeddedWalletSettingSaved,
        onChange: handleOnToggleChange,
        type: 'toggle',
      }}
    />
  );
};
