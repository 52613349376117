import { FC } from 'react';

import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { OpacityAnimation } from '../../animations';

import styles from './BooleanInput.module.scss';
import { BooleanInputProps } from './BooleanInput.types';
import { getType, getVariant, VariantIconMap } from './helpers';

export const BooleanInput: FC<BooleanInputProps> = ({
  label,
  name,
  value,
  checked,
  onChange,
  disabled,
  id,
  defaultChecked,
  isError,
  isSaved,
  isWarning,
  type,
  labelWeight = 'regular',
  className,
  customInputClassName,
}) => {
  const inputType = getType(type);

  const variant = getVariant({
    checked,
    disabled,
    isError,
    isSaved,
    isWarning,
  });
  const VariantIconToUse = VariantIconMap[variant];

  return (
    <div className={className}>
      <input
        type={inputType}
        className={styles.hiddenInput}
        name={name}
        id={id}
        value={value}
        onChange={!disabled ? onChange : undefined}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        data-testid={`boolean-input-${id}`}
      />
      <label htmlFor={id} className={styles.label}>
        {type === 'toggle' ? (
          <div
            className={classNames(
              styles.toggleWrapper,
              styles[`toggleWrapper--${variant}`],
            )}
          >
            <span
              className={classNames(
                styles.customInput,
                styles[`customInput--${variant}`],
                {
                  [styles['customInput--checked']]: checked,
                },
              )}
            >
              <AnimatePresence mode='wait' initial={false}>
                {VariantIconToUse && (
                  <OpacityAnimation key={variant}>
                    <Icon
                      size={14}
                      icon={VariantIconToUse}
                      className={styles.icon}
                    />
                  </OpacityAnimation>
                )}
              </AnimatePresence>
            </span>
          </div>
        ) : (
          <span
            className={classNames(
              styles.customInput,
              styles[`customInput--${variant}`],
              styles[`customInput--${type}`],
              customInputClassName,
            )}
          >
            <AnimatePresence mode='wait' initial={false}>
              {VariantIconToUse && (
                <OpacityAnimation key={variant}>
                  <Icon size={14} icon={VariantIconToUse} />
                </OpacityAnimation>
              )}
            </AnimatePresence>
          </span>
        )}

        {label && (
          <Typography variant='paragraph-2' weight={labelWeight}>
            {label}
          </Typography>
        )}
      </label>
    </div>
  );
};
