/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    CustomHostname,
    CustomHostnameFromJSON,
    CustomHostnameToJSON,
    CustomHostnameCreateRequest,
    CustomHostnameCreateRequestFromJSON,
    CustomHostnameCreateRequestToJSON,
    CustomHostnamesResponse,
    CustomHostnamesResponseFromJSON,
    CustomHostnamesResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateCustomHostnameRequest {
    environmentId: string;
    customHostnameCreateRequest: CustomHostnameCreateRequest;
}

export interface DeleteCustomHostnameRequest {
    environmentId: string;
    customHostnameId: string;
}

export interface GetCustomHostnamesRequest {
    environmentId: string;
}

/**
 * 
 */
export class CustomHostnamesApi extends runtime.BaseAPI {

    /**
     * Create a new custom hostname for this environment
     */
    async createCustomHostnameRaw(requestParameters: CreateCustomHostnameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomHostname>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createCustomHostname.');
        }

        if (requestParameters.customHostnameCreateRequest === null || requestParameters.customHostnameCreateRequest === undefined) {
            throw new runtime.RequiredError('customHostnameCreateRequest','Required parameter requestParameters.customHostnameCreateRequest was null or undefined when calling createCustomHostname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/custom/hostnames`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomHostnameCreateRequestToJSON(requestParameters.customHostnameCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomHostnameFromJSON(jsonValue));
    }

    /**
     * Create a new custom hostname for this environment
     */
    async createCustomHostname(requestParameters: CreateCustomHostnameRequest, initOverrides?: RequestInit): Promise<CustomHostname> {
        const response = await this.createCustomHostnameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the custom hostname
     */
    async deleteCustomHostnameRaw(requestParameters: DeleteCustomHostnameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteCustomHostname.');
        }

        if (requestParameters.customHostnameId === null || requestParameters.customHostnameId === undefined) {
            throw new runtime.RequiredError('customHostnameId','Required parameter requestParameters.customHostnameId was null or undefined when calling deleteCustomHostname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/custom/hostnames/{customHostnameId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"customHostnameId"}}`, encodeURIComponent(String(requestParameters.customHostnameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the custom hostname
     */
    async deleteCustomHostname(requestParameters: DeleteCustomHostnameRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCustomHostnameRaw(requestParameters, initOverrides);
    }

    /**
     * Get the custom hostnames for this environemnt
     */
    async getCustomHostnamesRaw(requestParameters: GetCustomHostnamesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomHostnamesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getCustomHostnames.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/custom/hostnames`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomHostnamesResponseFromJSON(jsonValue));
    }

    /**
     * Get the custom hostnames for this environemnt
     */
    async getCustomHostnames(requestParameters: GetCustomHostnamesRequest, initOverrides?: RequestInit): Promise<CustomHostnamesResponse> {
        const response = await this.getCustomHostnamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
