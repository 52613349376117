import { useCallback, useRef, MouseEvent } from 'react';

import { Root, Trigger, Content, Arrow } from '@radix-ui/react-tooltip';
import classNames from 'classnames';

import { Typography } from '../Typography';

import { TooltipComponent } from './Tooltip.types';
import styles from './Tooltip.module.scss';

/** Northstar base Tooltip component */
export const Tooltip: TooltipComponent = ({
  children,
  align,
  side,
  variant,
  triggerClassName,
  content,
  open,
  defaultOpen,
  onOpenChange,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);

  /**
   * This prevents the tooltip from closing when the trigger is clicked
   *
   * By default, the tooltip is programmed to close when the trigger is clicked
   * but that breaks the [CopyField] component.
   */
  const preventDefault = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={0}
    >
      <Trigger
        asChild
        onClick={preventDefault}
        onPointerDown={preventDefault}
        onMouseDown={preventDefault}
      >
        <div
          ref={triggerRef}
          className={triggerClassName}
          data-testid='tooltip-trigger'
        >
          {children}
        </div>
      </Trigger>
      <Content
        side={side}
        align={align}
        className={classNames(styles.content, styles[variant ?? 'default'])}
        data-testid='tooltip-content'
      >
        {typeof content === 'string' ? (
          <Typography variant='paragraph-1' color='inherit'>
            {content}
          </Typography>
        ) : (
          content
        )}
        <Arrow
          width={11}
          height={5}
          className={classNames(styles.arrow, styles[variant ?? 'default'])}
        />
      </Content>
    </Root>
  );
};
