import { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  Button,
  Input,
  SingleToggleCard,
} from '@dynamic-labs/northstar';
import { RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardRole } from '../../../../../../app/hooks/useDashboardRole';
import { useNameServicesContext } from '../../../../../../app/context/NameServicesContext';
import { RemoveSubdomainDialog } from '../RemoveSubdomainDialog';

import styles from './ExistingSubdomains.module.scss';

/** Page that renders existing subdomains */
export const ExistingSubdomains: FC = () => {
  const { t } = useTranslation();

  const { nameServices } = useNameServicesContext();
  const { checkForAccess } = useDashboardRole();

  const [isRemoveSubdomainDialogOpen, setIsRemoveSubdomainDialogOpen] =
    useState(false);

  const isOwner = useMemo(
    () => checkForAccess(RoleEnum.Owner),
    [checkForAccess],
  );

  return (
    <SingleToggleCard
      accordionKey='active-subdomain'
      title={t(
        'v2.page.global_wallets.subdomains.drawer.active_subdomain.title',
      )}
      description={t(
        'v2.page.global_wallets.subdomains.drawer.active_subdomain.description',
      )}
      allowExpand
      defaultExpanded
      customActionIcon={{
        Icon: <> </>,
      }}
    >
      <div className={styles.card_content}>
        <div className={styles.input_container}>
          <Input
            className={styles.input}
            name='ens-domain'
            value={nameServices?.domain}
            readOnly
            label={t('v2.page.global_wallets.subdomains.drawer.input_label')}
          />
          {isOwner && (
            <>
              <Button
                className={styles.remove_button}
                variant='secondary'
                size='large'
                text={t(
                  'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_button',
                )}
                onClick={() => setIsRemoveSubdomainDialogOpen(true)}
              />
              <RemoveSubdomainDialog
                open={isRemoveSubdomainDialogOpen}
                onOpenChange={setIsRemoveSubdomainDialogOpen}
              />
            </>
          )}
        </div>
        {!isOwner && (
          <div className={styles.alert}>
            <Alert
              title={t(
                'v2.page.global_wallets.subdomains.drawer.active_subdomain.permissions_alert.title',
              )}
              description={t(
                'v2.page.global_wallets.subdomains.drawer.active_subdomain.permissions_alert.description',
              )}
              variant='warning'
            />
          </div>
        )}
      </div>
    </SingleToggleCard>
  );
};
