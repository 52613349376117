/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EcdsaValidatorOptions,
    EcdsaValidatorOptionsFromJSON,
    EcdsaValidatorOptionsFromJSONTyped,
    EcdsaValidatorOptionsToJSON,
} from './EcdsaValidatorOptions';
import {
    ProviderCreateRequestMultichainAccountAbstractionProviders,
    ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSON,
    ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSONTyped,
    ProviderCreateRequestMultichainAccountAbstractionProvidersToJSON,
} from './ProviderCreateRequestMultichainAccountAbstractionProviders';
import {
    ProviderEntryPointVersionEnum,
    ProviderEntryPointVersionEnumFromJSON,
    ProviderEntryPointVersionEnumFromJSONTyped,
    ProviderEntryPointVersionEnumToJSON,
} from './ProviderEntryPointVersionEnum';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';
import {
    ProviderKernelVersionEnum,
    ProviderKernelVersionEnumFromJSON,
    ProviderKernelVersionEnumFromJSONTyped,
    ProviderKernelVersionEnumToJSON,
} from './ProviderKernelVersionEnum';
import {
    SmsCountryCode,
    SmsCountryCodeFromJSON,
    SmsCountryCodeFromJSONTyped,
    SmsCountryCodeToJSON,
} from './SmsCountryCode';

/**
 * Request body to create a new provider. The provider will be not enabled by default.
 * @export
 * @interface ProviderCreateRequest
 */
export interface ProviderCreateRequest {
    /**
     * 
     * @type {ProviderEnum}
     * @memberof ProviderCreateRequest
     */
    provider: ProviderEnum;
    /**
     * Standard OAuth client ID. For more information, see: https://www.oauth.com/oauth2-servers/client-registration/client-id-secret/. For Apple, this is also known as the Service ID.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    clientId?: string;
    /**
     * Standard OAuth client secret key. For more information, see: https://www.oauth.com/oauth2-servers/client-registration/client-id-secret/
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    clientSecret?: string;
    /**
     * Some providers require additional information, typically called a project ID or site ID
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    providerProjectId?: string;
    /**
     * The default chain id the SDK should use
     * @type {number}
     * @memberof ProviderCreateRequest
     */
    defaultChainId?: number;
    /**
     * CAIP-2 Chain ID (https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-2.md)
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    defaultChain?: string;
    /**
     * The url of the site to go to export wallets private keys
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    keyExportUrl?: string;
    /**
     * The url of the terms and conditions or privacy policy needed to be shown to the client when creating a new provider that requires terms and conditions to be signed before starting configuration.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    termsUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderCreateRequest
     */
    useDynamicCredentials?: boolean;
    /**
     * Optional custom space-delimited list of Oauth scopes for the social provider
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    scopes?: string;
    /**
     * Key ID required for Apple Oauth2 applications. This is the identifier for a private key.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    appleKeyId?: string;
    /**
     * Team ID required for Apple Oauth2 applications. This is associated with the Apple developer membership account.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    appleTeamId?: string;
    /**
     * Shopify store required for Shopify Oauth2 applications.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    shopifyStore?: string;
    /**
     * Required to initialize Twilio provider.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    accountSid?: string;
    /**
     * Required to initialize Twilio provider. Phone number used to send SMS messages.
     * @type {string}
     * @memberof ProviderCreateRequest
     */
    twilioNumber?: string;
    /**
     * 
     * @type {Array<SmsCountryCode>}
     * @memberof ProviderCreateRequest
     */
    enabledCountries?: Array<SmsCountryCode>;
    /**
     * 
     * @type {Array<ProviderCreateRequestMultichainAccountAbstractionProviders>}
     * @memberof ProviderCreateRequest
     */
    multichainAccountAbstractionProviders?: Array<ProviderCreateRequestMultichainAccountAbstractionProviders>;
    /**
     * 
     * @type {ProviderEntryPointVersionEnum}
     * @memberof ProviderCreateRequest
     */
    entryPointVersion?: ProviderEntryPointVersionEnum;
    /**
     * 
     * @type {ProviderKernelVersionEnum}
     * @memberof ProviderCreateRequest
     */
    kernelVersion?: ProviderKernelVersionEnum;
    /**
     * 
     * @type {EcdsaValidatorOptions}
     * @memberof ProviderCreateRequest
     */
    ecdsaProviderType?: EcdsaValidatorOptions;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderCreateRequest
     */
    enableKernelV3Migration?: boolean;
}

export function ProviderCreateRequestFromJSON(json: any): ProviderCreateRequest {
    return ProviderCreateRequestFromJSONTyped(json, false);
}

export function ProviderCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': ProviderEnumFromJSON(json['provider']),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'providerProjectId': !exists(json, 'providerProjectId') ? undefined : json['providerProjectId'],
        'defaultChainId': !exists(json, 'defaultChainId') ? undefined : json['defaultChainId'],
        'defaultChain': !exists(json, 'defaultChain') ? undefined : json['defaultChain'],
        'keyExportUrl': !exists(json, 'keyExportUrl') ? undefined : json['keyExportUrl'],
        'termsUrl': !exists(json, 'termsUrl') ? undefined : json['termsUrl'],
        'useDynamicCredentials': !exists(json, 'useDynamicCredentials') ? undefined : json['useDynamicCredentials'],
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
        'appleKeyId': !exists(json, 'appleKeyId') ? undefined : json['appleKeyId'],
        'appleTeamId': !exists(json, 'appleTeamId') ? undefined : json['appleTeamId'],
        'shopifyStore': !exists(json, 'shopifyStore') ? undefined : json['shopifyStore'],
        'accountSid': !exists(json, 'accountSid') ? undefined : json['accountSid'],
        'twilioNumber': !exists(json, 'twilioNumber') ? undefined : json['twilioNumber'],
        'enabledCountries': !exists(json, 'enabledCountries') ? undefined : ((json['enabledCountries'] as Array<any>).map(SmsCountryCodeFromJSON)),
        'multichainAccountAbstractionProviders': !exists(json, 'multichainAccountAbstractionProviders') ? undefined : ((json['multichainAccountAbstractionProviders'] as Array<any>).map(ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSON)),
        'entryPointVersion': !exists(json, 'entryPointVersion') ? undefined : ProviderEntryPointVersionEnumFromJSON(json['entryPointVersion']),
        'kernelVersion': !exists(json, 'kernelVersion') ? undefined : ProviderKernelVersionEnumFromJSON(json['kernelVersion']),
        'ecdsaProviderType': !exists(json, 'ecdsaProviderType') ? undefined : EcdsaValidatorOptionsFromJSON(json['ecdsaProviderType']),
        'enableKernelV3Migration': !exists(json, 'enableKernelV3Migration') ? undefined : json['enableKernelV3Migration'],
    };
}

export function ProviderCreateRequestToJSON(value?: ProviderCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderEnumToJSON(value.provider),
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'providerProjectId': value.providerProjectId,
        'defaultChainId': value.defaultChainId,
        'defaultChain': value.defaultChain,
        'keyExportUrl': value.keyExportUrl,
        'termsUrl': value.termsUrl,
        'useDynamicCredentials': value.useDynamicCredentials,
        'scopes': value.scopes,
        'appleKeyId': value.appleKeyId,
        'appleTeamId': value.appleTeamId,
        'shopifyStore': value.shopifyStore,
        'accountSid': value.accountSid,
        'twilioNumber': value.twilioNumber,
        'enabledCountries': value.enabledCountries === undefined ? undefined : ((value.enabledCountries as Array<any>).map(SmsCountryCodeToJSON)),
        'multichainAccountAbstractionProviders': value.multichainAccountAbstractionProviders === undefined ? undefined : ((value.multichainAccountAbstractionProviders as Array<any>).map(ProviderCreateRequestMultichainAccountAbstractionProvidersToJSON)),
        'entryPointVersion': ProviderEntryPointVersionEnumToJSON(value.entryPointVersion),
        'kernelVersion': ProviderKernelVersionEnumToJSON(value.kernelVersion),
        'ecdsaProviderType': EcdsaValidatorOptionsToJSON(value.ecdsaProviderType),
        'enableKernelV3Migration': value.enableKernelV3Migration,
    };
}

