/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnalyticsVisitResponse,
    AnalyticsVisitResponseFromJSON,
    AnalyticsVisitResponseToJSON,
    BadGateway,
    BadGatewayFromJSON,
    BadGatewayToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface GetVisitAnalyticsRequest {
    environmentId: string;
    startDate?: string;
    endDate?: string;
}

export interface GetWalletsBreakdownRequest {
    environmentId: string;
    startDate?: string;
    endDate?: string;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     * Fetch visit analytics
     */
    async getVisitAnalyticsRaw(requestParameters: GetVisitAnalyticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnalyticsVisitResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getVisitAnalytics.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/analytics/visits`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalyticsVisitResponseFromJSON(jsonValue));
    }

    /**
     * Fetch visit analytics
     */
    async getVisitAnalytics(requestParameters: GetVisitAnalyticsRequest, initOverrides?: RequestInit): Promise<AnalyticsVisitResponse> {
        const response = await this.getVisitAnalyticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch wallets breakdown
     */
    async getWalletsBreakdownRaw(requestParameters: GetWalletsBreakdownRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getWalletsBreakdown.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/analytics/wallets`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fetch wallets breakdown
     */
    async getWalletsBreakdown(requestParameters: GetWalletsBreakdownRequest, initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.getWalletsBreakdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
