/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GlobalWalletSettings,
    GlobalWalletSettingsFromJSON,
    GlobalWalletSettingsToJSON,
    GlobalWalletSettingsCreateRequest,
    GlobalWalletSettingsCreateRequestFromJSON,
    GlobalWalletSettingsCreateRequestToJSON,
    GlobalWalletSettingsUpdateRequest,
    GlobalWalletSettingsUpdateRequestFromJSON,
    GlobalWalletSettingsUpdateRequestToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateGlobalWalletSettingsRequest {
    environmentId: string;
    globalWalletSettingsCreateRequest: GlobalWalletSettingsCreateRequest;
}

export interface DisableGlobalWalletRequest {
    environmentId: string;
}

export interface EnableGlobalWalletRequest {
    environmentId: string;
}

export interface GetGlobalWalletSettingsRequest {
    environmentId: string;
}

export interface UpdateGlobalWalletSettingsRequest {
    environmentId: string;
    globalWalletSettingsUpdateRequest: GlobalWalletSettingsUpdateRequest;
}

/**
 * 
 */
export class GlobalWalletsApi extends runtime.BaseAPI {

    /**
     * Creates the global wallet settings for the environment
     */
    async createGlobalWalletSettingsRaw(requestParameters: CreateGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GlobalWalletSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createGlobalWalletSettings.');
        }

        if (requestParameters.globalWalletSettingsCreateRequest === null || requestParameters.globalWalletSettingsCreateRequest === undefined) {
            throw new runtime.RequiredError('globalWalletSettingsCreateRequest','Required parameter requestParameters.globalWalletSettingsCreateRequest was null or undefined when calling createGlobalWalletSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/globalWallet/settings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GlobalWalletSettingsCreateRequestToJSON(requestParameters.globalWalletSettingsCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalWalletSettingsFromJSON(jsonValue));
    }

    /**
     * Creates the global wallet settings for the environment
     */
    async createGlobalWalletSettings(requestParameters: CreateGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<GlobalWalletSettings> {
        const response = await this.createGlobalWalletSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Disabled global wallet for this environment
     */
    async disableGlobalWalletRaw(requestParameters: DisableGlobalWalletRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GlobalWalletSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling disableGlobalWallet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/globalWallet/disable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalWalletSettingsFromJSON(jsonValue));
    }

    /**
     * Disabled global wallet for this environment
     */
    async disableGlobalWallet(requestParameters: DisableGlobalWalletRequest, initOverrides?: RequestInit): Promise<GlobalWalletSettings> {
        const response = await this.disableGlobalWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable global wallet for this environment
     */
    async enableGlobalWalletRaw(requestParameters: EnableGlobalWalletRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GlobalWalletSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling enableGlobalWallet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/globalWallet/enable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalWalletSettingsFromJSON(jsonValue));
    }

    /**
     * Enable global wallet for this environment
     */
    async enableGlobalWallet(requestParameters: EnableGlobalWalletRequest, initOverrides?: RequestInit): Promise<GlobalWalletSettings> {
        const response = await this.enableGlobalWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the global wallet settings for environment
     */
    async getGlobalWalletSettingsRaw(requestParameters: GetGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GlobalWalletSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getGlobalWalletSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/globalWallet/settings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalWalletSettingsFromJSON(jsonValue));
    }

    /**
     * Get the global wallet settings for environment
     */
    async getGlobalWalletSettings(requestParameters: GetGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<GlobalWalletSettings> {
        const response = await this.getGlobalWalletSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the global wallet settings for the environment
     */
    async updateGlobalWalletSettingsRaw(requestParameters: UpdateGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GlobalWalletSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateGlobalWalletSettings.');
        }

        if (requestParameters.globalWalletSettingsUpdateRequest === null || requestParameters.globalWalletSettingsUpdateRequest === undefined) {
            throw new runtime.RequiredError('globalWalletSettingsUpdateRequest','Required parameter requestParameters.globalWalletSettingsUpdateRequest was null or undefined when calling updateGlobalWalletSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/globalWallet/settings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GlobalWalletSettingsUpdateRequestToJSON(requestParameters.globalWalletSettingsUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalWalletSettingsFromJSON(jsonValue));
    }

    /**
     * Updates the global wallet settings for the environment
     */
    async updateGlobalWalletSettings(requestParameters: UpdateGlobalWalletSettingsRequest, initOverrides?: RequestInit): Promise<GlobalWalletSettings> {
        const response = await this.updateGlobalWalletSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
