import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../app/context/SettingsContext';
import { useChainalysisContext } from '../../../app/context/ChainalysisContext/ChainalysisContext';
import { chainalysisApi } from '../../../app/services/api';
import { logger } from '../../../app/services/logger';

export type UseSaveSecuritySettingsReturnType = {
  handleReset: () => void;
  handleSave: () => void;
  isLoading: boolean;
  shouldShowSaveBanner: boolean;
};

export const useSaveSecuritySettings =
  (): UseSaveSecuritySettingsReturnType => {
    const { activeEnvironmentType, activeEnvironment } =
      useEnvironmentsContext();
    const {
      settings,
      initialSettings,
      settingsHasChangedForActiveEnv: projectSettingsHasChanged,
      updateInitialSettingsForActiveEnv,
      cancelChangesForActiveEnv,
    } = useSettingsContext();
    const {
      configuration,
      newConfiguration,
      setNewConfiguration,
      setConfiguration,
    } = useChainalysisContext();

    const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
      useUpdateProjectSettings({
        onSuccess: () => {
          updateInitialSettingsForActiveEnv();
        },
      });

    const isChainalysisChanged = Boolean(
      configuration[activeEnvironmentType].sanctionsApiEnabled !==
        newConfiguration[activeEnvironmentType].sanctionsApiEnabled,
    );

    const isHCaptchaChanged = Boolean(
      settings[activeEnvironmentType]?.security?.hCaptcha?.enabled !==
        initialSettings[activeEnvironmentType]?.security?.hCaptcha?.enabled ||
        settings[activeEnvironmentType]?.security?.hCaptcha?.siteKey !==
          initialSettings[activeEnvironmentType]?.security?.hCaptcha?.siteKey ||
        settings[activeEnvironmentType]?.security?.hCaptcha?.secretKey !==
          initialSettings[activeEnvironmentType]?.security?.hCaptcha?.secretKey,
    );

    const shouldShowSaveBanner =
      projectSettingsHasChanged || isChainalysisChanged || isHCaptchaChanged;

    const handleSave = async () => {
      if (shouldShowSaveBanner) {
        // Save project settings if either project settings or hCaptcha settings have changed
        if (projectSettingsHasChanged || isHCaptchaChanged) {
          saveProjectSettings({
            environmentId: activeEnvironment?.id || '',
            projectSettings: settings[activeEnvironmentType],
          });
        }

        if (isChainalysisChanged) {
          try {
            const newConfig = newConfiguration[activeEnvironmentType]
              .sanctionsApiEnabled
              ? await chainalysisApi.enableSanctionsApiByEnvironmentId({
                  environmentId: activeEnvironment?.id || '',
                })
              : await chainalysisApi.disableSanctionsApiByEnvironmentId({
                  environmentId: activeEnvironment?.id || '',
                });

            setNewConfiguration({
              ...newConfiguration,
              [activeEnvironmentType]: newConfig,
            });
            setConfiguration({
              ...configuration,
              [activeEnvironmentType]: newConfig,
            });
          } catch (error) {
            logger.error('Error updating Chainalysis settings:', error);
          }
        }
      }
    };

    const handleReset = () => {
      // Reset settings context
      cancelChangesForActiveEnv();

      // Reset Chainalysis context
      setNewConfiguration(configuration);
    };

    return {
      handleReset,
      handleSave,
      isLoading: isSavingProjectSettings,
      shouldShowSaveBanner,
    };
  };
