import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const getGlobalWalletSettingsValidationSchema = (t: TFunction) => {
  const walletNameRegex = /^$|^(?=\S)[a-zA-Z0-9 ]+(?<=\S)$/u;
  const walletIconRegex = /.*\.(png|svg)$/i;
  const customCssRegex = /.*\.(css)$/i;

  const validationSchema = object().shape({
    customCssUrl: string()
      .url(
        t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.custom_css_url_invalid_url',
        ),
      )
      .matches(customCssRegex, {
        message: t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.custom_css_url_invalid_format',
        ),
      }),
    popupPageTitle: string().max(
      50,
      t(
        'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.popup_page_title_max_50_characters',
      ),
    ),
    walletIconUrl: string()
      .url(
        t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.wallet_icon_url_invalid_url',
        ),
      )
      .matches(walletIconRegex, {
        message: t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.wallet_icon_url_invalid_format',
        ),
      }),
    walletName: string()
      .required(
        t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.wallet_name_required',
        ),
      )
      .max(
        50,
        t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.wallet_name_max_50_characters',
        ),
      )
      .matches(walletNameRegex, {
        message: t(
          'v2.page.global_wallets.domain_and_wallet_info.wallet_information.form_errors.wallet_name_invalid_format',
        ),
      }),
  });

  return validationSchema;
};
