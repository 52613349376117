import { useState } from 'react';

import {
  Export,
  ExportFormatEnum,
  ExportModelEnum,
  ExportStatusEnum,
} from '@dynamic-labs/sdk-api';

import {
  useCreateExportMutation,
  useFetchExportsQuery,
  useFetchDetailsForExportsQuery,
} from '../helpers';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
// This hook takes care about whole users exports logic
export const useExportsData = (model: ExportModelEnum) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const [allExports, setAllExports] = useState<Export[]>([]);
  const [pendingExportsIds, setPendingExportsIds] = useState<string[]>([]);

  // Fetch all exports for active environment
  const { refetch: refetchAllExports } = useFetchExportsQuery(
    activeEnvironment?.id || '',
    model,
    {
      enabled: !!activeEnvironment?.id,
      onSuccess: (data) => {
        const fetchedExports = data?.exports || [];

        setAllExports(fetchedExports);

        const pendingExports = fetchedExports.filter(
          (exportItem) => exportItem.status === ExportStatusEnum.Pending,
        );
        setPendingExportsIds(pendingExports.map((exportItem) => exportItem.id));
      },
      refetchOnWindowFocus: false,
    },
  );

  // Create new export
  const { mutate: createExport } = useCreateExportMutation(
    activeEnvironment?.id || '',
    {
      format: ExportFormatEnum.Csv,
      model,
    },
    {
      onSuccess: () => {
        refetchAllExports();
      },
    },
  );

  // Fetch details ONLY for pending exports
  const { refetch: refetchPendingQueries } = useFetchDetailsForExportsQuery(
    pendingExportsIds,
    {
      enabled: !!pendingExportsIds.length,
      onSuccess: (data) => {
        const updatedExports = allExports.map((oneExport) => {
          if (oneExport.id === data.id) {
            return data;
          }

          return oneExport;
        });
        // Update all exports with new data
        setAllExports(updatedExports);

        // Update pending exports ids to remove already completed
        setPendingExportsIds(
          updatedExports
            .filter(
              (exportItem) => exportItem.status === ExportStatusEnum.Pending,
            )
            .map((exportItem) => exportItem.id),
        );
      },
      refetchInterval: pendingExportsIds.length > 0 && 1000,
    },
  );

  return {
    allExports,
    createExport,
    // disable refetch button if there is no pending exports
    disableAddButton: pendingExportsIds.length > 0,
    disableRefetchButton: pendingExportsIds.length === 0, // disable add button if there is at least one pending export
    refetch: refetchPendingQueries,
  };
};
