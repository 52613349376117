import { FC } from 'react';

import { CustomHostname, CustomHostnameUsageEnum } from '@dynamic-labs/sdk-api';

import { AddDomain } from './AddDomain';
import { DomainInfo } from './DomainInfo';

type DomainSectionProps = {
  domainUsage: CustomHostnameUsageEnum;
  hostname?: CustomHostname;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
};

export const DomainSection: FC<DomainSectionProps> = ({
  domainUsage,
  hostname,
  refetch,
  isLoading,
  isRefetching,
}) => {
  if (!hostname) {
    return (
      <AddDomain
        domainUsage={domainUsage}
        refetch={refetch}
        disabled={isLoading}
      />
    );
  }

  return (
    <DomainInfo
      hostname={hostname}
      refetch={refetch}
      isRefetching={isRefetching}
    />
  );
};
