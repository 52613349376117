import React, { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';

type TransactionSimulationToggleProps = {
  disabled: boolean;
};

export const TransactionSimulationToggle: FC<
  TransactionSimulationToggleProps
> = ({ disabled }) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isTransactionSimulationEnabled = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.transactionSimulation &&
      isTurnkeyEnabled &&
      !disabled,
  );

  const isTransactionSimulationSettingSaved = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.transactionSimulation ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.transactionSimulation,
  );

  const handleTransactionSimulationChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            transactionSimulation: event.target.checked,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.embedded_wallets.dynamic_section.settings.transaction_simulation.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.settings.transaction_simulation.description',
      )}
      accordionKey='transaction_simulation'
      inputProps={{
        checked: isTurnkeyEnabled && isTransactionSimulationEnabled,
        disabled: !isTurnkeyEnabled || disabled,
        id: 'transaction_simulation',
        isSaved: isTurnkeySettingSaved && isTransactionSimulationSettingSaved,
        onChange: handleTransactionSimulationChange,
        type: 'toggle',
      }}
    />
  );
};
