import { FC } from 'react';

import { TooltipProvider } from '@radix-ui/react-tooltip';

import { RedcoastQueryContextProvider } from '@dynamic-labs/redcoast-query';

import { CustomFieldsProvider } from '../../../app/context/CustomFieldsContext';
import { SettingsContextProvider } from '../../../app/context/SettingsContext';
import { DashboardContextProvider } from '../../../app/context/DashboardContext';
import { EnvironmentsContextProvider } from '../../../app/context/EnvironmentsContext';
import { OriginsContextProvider } from '../../../app/context/OriginsContext';
import { TokensContextProvider } from '../../../app/context/TokensContext';
import { AccessListsContextProvider } from '../../../app/context/AccessListsContext';
import { SubscriptionContextProvider } from '../../../app/context/SubscriptionContext';
import { ChainalysisContextProvider } from '../../../app/context/ChainalysisContext';
import { GatesContextProvider } from '../../../app/context/GatesContext';
import { ProvidersContextProvider } from '../../../app/context/ProvidersContext';
import { SaveBannerContextProvider } from '../../contexts/SaveBannerContext';
import { DashboardLayout } from '../../components';
import { baseUrl } from '../../../app/services/api';
import { LaunchDarklyProvider } from '../../utils';

import styles from './dashboard.module.scss';

export const Dashboard: FC = () => (
  <div className={styles.container}>
    <RedcoastQueryContextProvider baseUrl={baseUrl}>
      <DashboardContextProvider>
        <SubscriptionContextProvider>
          <EnvironmentsContextProvider>
            <SettingsContextProvider>
              <TokensContextProvider>
                <GatesContextProvider>
                  <AccessListsContextProvider>
                    <ChainalysisContextProvider>
                      <OriginsContextProvider>
                        <ProvidersContextProvider>
                          <CustomFieldsProvider>
                            <SaveBannerContextProvider>
                              <LaunchDarklyProvider>
                                <TooltipProvider>
                                  <DashboardLayout />
                                </TooltipProvider>
                              </LaunchDarklyProvider>
                            </SaveBannerContextProvider>
                          </CustomFieldsProvider>
                        </ProvidersContextProvider>
                      </OriginsContextProvider>
                    </ChainalysisContextProvider>
                  </AccessListsContextProvider>
                </GatesContextProvider>
              </TokensContextProvider>
            </SettingsContextProvider>
          </EnvironmentsContextProvider>
        </SubscriptionContextProvider>
      </DashboardContextProvider>
    </RedcoastQueryContextProvider>
  </div>
);
