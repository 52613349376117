/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalWalletFundingDefaultChain {
    Ethereum = 'ethereum',
    Solana = 'solana'
}

export function ExternalWalletFundingDefaultChainFromJSON(json: any): ExternalWalletFundingDefaultChain {
    return ExternalWalletFundingDefaultChainFromJSONTyped(json, false);
}

export function ExternalWalletFundingDefaultChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalWalletFundingDefaultChain {
    return json as ExternalWalletFundingDefaultChain;
}

export function ExternalWalletFundingDefaultChainToJSON(value?: ExternalWalletFundingDefaultChain | null): any {
    return value as any;
}

