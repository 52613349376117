import { useTranslation } from 'react-i18next';

import { TimeUnitEnum } from '@dynamic-labs/sdk-api';
import { DropdownMenu } from '@dynamic-labs/northstar';

import { Typography } from '../../../../app/components/Typography';
import { Tooltip } from '../../../../app/components/Tooltip';

import styles from './TimeTypeDropDown.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  disabledTooltipText?: string[];
  setUnit: (unit: TimeUnitEnum) => void;
  unit: TimeUnitEnum;
  values: TimeUnitEnum[];
};

export const TimeTypeDropDown = ({
  unit,
  setUnit,
  values,
  disabled = false,
  disabledTooltipText = [],
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      tooltipText={disabledTooltipText}
      disableHover={!disabled || disabledTooltipText?.length === 0}
    >
      <DropdownMenu
        disabled={disabled}
        options={values.map((value) => (
          <DropdownMenu.Item value={value} rawOption={value}>
            <Typography variant='paragraph-2' weight='medium'>
              {t(`settings_page.security.jwt.options.${value}`)}
            </Typography>
          </DropdownMenu.Item>
        ))}
        value={unit}
        allowUndefinedValue={false}
        onSelect={(value) => value && setUnit(value)}
        zIndex={1000}
        className={className ?? styles.dropdown}
        itemsSideOffset={8}
      />
    </Tooltip>
  );
};
