import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SideDrawer } from '../../../../../components/SideDrawer';
import { ManageCustomDomain } from '../../../../../components';
import { COOKIES_URL } from '../../../../../../app/utils/constants';

import styles from './CookieSidePage.module.scss';

type CookieSidePageProps = {
  cookiesDomain: string;
  onClose: () => void;
  open: boolean;
};

export const CookieSidePage: FC<CookieSidePageProps> = ({
  cookiesDomain,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <SideDrawer
      title={t(
        'v2.page.security.cors_and_cookies_auth_row.cookie_row.side_drawer.title',
      )}
      description={t(
        'v2.page.security.cors_and_cookies_auth_row.cookie_row.side_drawer.description',
      )}
      isOpen={open}
      handleClose={onClose}
      className={styles['side-drawer']}
      docs={{
        text: t(
          'v2.page.security.cors_and_cookies_auth_row.cookie_row.side_drawer.learn_more',
        ),
        url: COOKIES_URL,
      }}
    >
      <div className={styles.content}>
        <ManageCustomDomain
          domain={cookiesDomain}
          description={t(
            'v2.page.security.cors_and_cookies_auth_row.cookie_row.side_drawer.manage_cookie_domain_description',
          )}
        />
      </div>
    </SideDrawer>
  );
};
