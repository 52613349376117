import type { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import { useChainalysisContext } from '../../../../app/context/ChainalysisContext/ChainalysisContext';
import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';

import { HCaptchaSection } from './HCaptchaSection';
import styles from './SecurityIntegrationsRow.module.scss';

export const SecurityIntegrationsRow: FC = () => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { configuration, newConfiguration, setNewConfiguration } =
    useChainalysisContext();

  const handleChainalysisToggle = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      setNewConfiguration({
        ...newConfiguration,
        [activeEnvironmentType]: {
          ...newConfiguration[activeEnvironmentType],
          sanctionsApiEnabled: event.target.checked,
        },
      });
    } catch {
      // eslint-disable-next-line no-console
      console.warn('Failed to update Chainalysis configuration');
    }
  };

  const isChainalysisEnabled = Boolean(
    newConfiguration[activeEnvironmentType].sanctionsApiEnabled,
  );

  const isChainalysisSaved = Boolean(
    configuration[activeEnvironmentType].sanctionsApiEnabled ===
      isChainalysisEnabled,
  );

  return (
    <Section
      title={t('v2.page.fraud_prevention.security_integrations.title')}
      description={t(
        'v2.page.fraud_prevention.security_integrations.description',
      )}
    >
      <div className={styles.section}>
        <SingleToggleCard
          accordionKey='chainalysis'
          title={t(
            'v2.page.fraud_prevention.security_integrations.chainalysis.title',
          )}
          description={t(
            'v2.page.fraud_prevention.security_integrations.chainalysis.description',
          )}
          allowExpand={false}
          inputProps={{
            checked: isChainalysisEnabled,
            id: 'chainalysis-toggle',
            isSaved: isChainalysisSaved,
            onChange: handleChainalysisToggle,
            type: 'toggle',
          }}
        />
        <HCaptchaSection disabled={false} />
      </div>
    </Section>
  );
};
