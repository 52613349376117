import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { ROUTES } from '../../../../../app/components/Navigation/data';
import { GlobalWalletConfig } from '../types';

type GlobalWalletToggleProps = {
  isEnabled: boolean;
  isLoading: boolean;
};

export const GlobalWalletToggle: FC<GlobalWalletToggleProps> = ({
  isEnabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { values, setFieldValue, initialValues } =
    useFormikContext<GlobalWalletConfig>();

  const handleToggleChange = useCallback(() => {
    const newToggleValue = !values.enabled;
    setFieldValue('enabled', newToggleValue);
  }, [setFieldValue, values]);

  return (
    <SingleToggleCard
      accordionKey='cookies-auth'
      title={t('v2.page.global_wallets.domain_and_wallet_info.toggle.title')}
      inputProps={{
        checked: values.enabled,
        disabled: !isEnabled || isLoading,
        id: 'GlobalWalletToggle',
        isSaved: values.enabled === initialValues.enabled,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
      allowExpand={false}
      collapsible={false}
      actionLink={
        !isEnabled
          ? {
              onClick: () => navigate(ROUTES.developerDomains),
              text: t(
                'v2.page.global_wallets.domain_and_wallet_info.toggle.action_link',
              ),
            }
          : undefined
      }
    />
  );
};
