import { TFunction } from 'react-i18next';

import { AllowlistEntry } from '@dynamic-labs/sdk-api';

import { TableSkeleton } from '../../../../../components/TableSkeleton';
import { DeleteEntryCell } from '../DeleteEntryCell/DeleteEntryCell';

export const listEntriesColumns = (
  t: TFunction<'translation', undefined>,
  deleteHandler: (entryId: string) => Promise<void>,
) => [
  {
    Header: t(
      'integrations.onboarding_and_kyc.access_control.access_list.columns.uuid',
    ),
    accessor: (row: AllowlistEntry) => (
      <div className='min-w-[110px]'>{row.id}</div>
    ),
    canToggleHidden: true,
    className: 'min-w-[180px] w-full',
    id: 'uuid',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t(
      'integrations.onboarding_and_kyc.access_control.access_list.columns.type',
    ),
    accessor: (row: AllowlistEntry) => {
      if (row.email)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.email',
        );
      if (row.emailDomain)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.emailDomain',
        );
      if (row.walletPublicKey)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.address',
        );
      if (row.phoneNumber)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.phoneNumber',
        );
      if (row.discordUsername)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.discordUsername',
        );
      if (row.twitterUsername)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.twitterUsername',
        );
      if (row.farcasterUsername)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.farcasterUsername',
        );
      if (row.farcasterFid)
        return t(
          'integrations.onboarding_and_kyc.access_control.access_list.columns.farcasterFid',
        );
      return t(
        'integrations.onboarding_and_kyc.access_control.access_list.columns.unknown',
      );
    },
    canToggleHidden: true,
    className: 'min-w-[180px] w-full',
    id: 'type',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t(
      'integrations.onboarding_and_kyc.access_control.access_list.columns.value',
    ),
    accessor: (row: AllowlistEntry) =>
      row.email ||
      row.emailDomain ||
      row.walletPublicKey ||
      row.phoneNumber ||
      row.discordUsername ||
      row.twitterUsername ||
      row.farcasterUsername ||
      row.farcasterFid ||
      'N/A',
    canToggleHidden: true,
    className: 'min-w-[180px] w-full',
    id: 'value',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t(
      'integrations.onboarding_and_kyc.access_control.access_list.columns.alias',
    ),
    accessor: (row: AllowlistEntry) => row.alias,
    canToggleHidden: false,
    className: 'min-w-[180px] w-full',
    id: 'alias',
    skeleton: <TableSkeleton />,
  },
  {
    accessor: (row: AllowlistEntry) => (
      <DeleteEntryCell handleDeleteClick={deleteHandler} entryId={row.id} />
    ),
    canToggleHidden: true,
    className: '',
    id: 'actions',
    skeleton: <TableSkeleton />,
  },
];
