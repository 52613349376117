import { UseQueryOptions, useQuery } from 'react-query';

import { ExportModelEnum, ExportsResponse } from '@dynamic-labs/sdk-api';

import { exportsApi } from '../../../../../services/api';

export const useFetchExportsQuery = (
  environmentId: string,
  model: ExportModelEnum,
  options: UseQueryOptions<ExportsResponse, Error>,
) =>
  useQuery<ExportsResponse, Error>(
    ['environmentId', environmentId, 'exports'],
    () => exportsApi.getEnvironmentExports({ environmentId, model }),
    options,
  );
