import { ChangeEvent, FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  AlertProps,
  Chain,
  Section,
  SingleToggleCard,
  Typography,
} from '@dynamic-labs/northstar';
import {
  ChainConfiguration,
  ChainEnum,
  EmbeddedWalletVersionEnum,
  ProjectSettingsChains,
  ProviderEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';

import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { AdminIcon, EmbeddedWalletIcon } from '../../../../../../icons';
import { SideDrawer } from '../../../../../components/SideDrawer';
import { useOriginsContext } from '../../../../../../app/context/OriginsContext';
import { useSubscriptionLock } from '../../../../../../app/hooks/useSubscriptionLock';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { ROUTES } from '../../../../../../app/components/Navigation/data';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../app/routes/Configurations/utils';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { ChangedSettingsIndicator } from '../../shared';
import { ChainNames } from '../../../../../../app/routes/Configurations/EmbeddedWallets/components/EmbeddedWalletChainConfigurationSection/EmbeddedWalletChainConfigurationSection';

import {
  EmbeddedWalletChainToggle,
  EmbeddedWalletSupportedChainEnum,
} from './EmbeddedWalletChainToggle';
import styles from './EmbeddedWalletRow.module.scss';
import { DefaultChainDropdown } from './DefaultChainDropdown';
import { ChainBadge } from './ChainBadge';
import { DefaultWalletVersionDropdown } from './DefaultWalletVersionDropdown';

const DOCS_URL =
  'https://docs.dynamic.xyz/wallets/embedded-wallets/dynamic-embedded-wallets';

type EmbeddedWalletRowProps = {
  disabled: boolean;
  refetchWalletsBreakdown: VoidFunction;
  setShowDowngradeModal: (value: boolean) => void;
};

export const EmbeddedWalletRow: FC<EmbeddedWalletRowProps> = ({
  disabled,
  refetchWalletsBreakdown,
  setShowDowngradeModal,
}) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.DynamicEmbeddedWallet,
  });

  const { t } = useTranslation();
  const { toggleProvider, getProviderValue, hasProviderChanged } =
    useProvidersContext();

  const navigation = useNavigate();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { originData } = useOriginsContext();
  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );
  const { settings, setSettings } = useSettingsContext();

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const environmentSettings = settings[activeEnvironmentType];
  const embeddedWalletVersion =
    environmentSettings?.sdk?.embeddedWallets?.defaultWalletVersion;

  const hasCorsOrigin = originData && originData.length > 0;

  const sectionAlerts = useMemo(() => {
    const alerts: AlertProps[] = [];

    if (!hasCorsOrigin && activeEnvironmentType === 'live') {
      alerts.push({
        action: {
          onClick: () => navigation(ROUTES.security),
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.action',
          ),
        },
        description: t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.description',
        ),
        title: t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.title',
        ),
        variant: 'warning',
      });
    }
    if (embeddedWalletVersion === EmbeddedWalletVersionEnum.V2) {
      alerts.push({
        action: {
          onClick: () => setShowDowngradeModal(true),
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.v2_downgrade_action',
          ),
        },
        title: t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.v2_upgrade_description',
        ),
        variant: 'info',
      });
    }

    if (shouldLockOnLive) {
      alerts.push({
        action: {
          onClick: () => navigation(ROUTES.adminSubscriptionTab),
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.paid.action',
          ),
        },
        description: t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.paid.description',
        ),
        title: t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.paid.title',
        ),
        variant: 'info',
      });
    }
    return alerts;
  }, [
    navigation,
    hasCorsOrigin,
    shouldLockOnLive,
    t,
    activeEnvironmentType,
    embeddedWalletVersion,
    setShowDowngradeModal,
  ]);

  const enabledChains =
    settings[activeEnvironmentType]?.chains
      .filter((chain: ProjectSettingsChains) => chain.enabled)
      .map((chain: ProjectSettingsChains) => ({
        chainName: chain.name,
      })) ?? [];

  const embeddedWalletEnabledChains: Chain[] =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.chainConfigurations
      ?.filter(
        (config: ChainConfiguration) =>
          config.enabled &&
          enabledChains.find(
            (chain) => chain.chainName === ChainNames[config.name as ChainEnum],
          ),
      )
      .map((config: ChainConfiguration) => ({
        chainName: config.name as ChainEnum,
      })) ?? [];

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleProvider(ProviderEnum.Turnkey, e.target.checked);
    refetchWalletsBreakdown();

    if (!e.target.checked) {
      return;
    }

    if (enabledChains.length === 0) {
      return;
    }

    // if enabling embedded wallets check which chains to enable too
    let chainConfigurations =
      settings[activeEnvironmentType]?.sdk?.embeddedWallets
        ?.chainConfigurations || [];

    const isEvmEnabled = enabledChains.some(
      (chain) => chain.chainName === ChainNames[ChainEnum.Evm],
    );
    const isSolEnabled = enabledChains.some(
      (chain) => chain.chainName === ChainNames[ChainEnum.Sol],
    );

    chainConfigurations?.forEach((c: ChainConfiguration) => {
      c.primary = false;
      c.enabled = false;
    });

    if (isEvmEnabled) {
      chainConfigurations = [
        ...chainConfigurations.filter(
          (config: ChainConfiguration) => config.name !== ChainEnum.Evm,
        ),
        {
          enabled: true,
          name: ChainEnum.Evm,
          primary: true,
        },
      ];
    }

    if (isSolEnabled) {
      chainConfigurations = [
        ...chainConfigurations.filter(
          (config: ChainConfiguration) => config.name !== ChainEnum.Sol,
        ),
        {
          enabled: true,
          name: ChainEnum.Sol,
          primary: !isEvmEnabled,
        },
      ];
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            chainConfigurations,
          },
        },
      },
    });
  };

  const { enableV3EmbeddedWallets } = useFlags();

  const isDynamicWaasEnabled = Boolean(
    settings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.defaultWalletVersion === EmbeddedWalletVersionEnum.V3,
  );

  return (
    <>
      <Section
        title={
          embeddedWalletVersion === EmbeddedWalletVersionEnum.V1
            ? t(
                'v2.page.embedded_wallets.dynamic_section.embedded_wallet.title',
              )
            : t(
                'v2.page.embedded_wallets.dynamic_section.embedded_wallet.title_v2',
              )
        }
        description={t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.description',
        )}
        alerts={sectionAlerts}
      >
        <SingleToggleCard
          allowExpand={false}
          title={t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.title',
          )}
          accordionKey='turnkey'
          inputProps={{
            checked: isTurnkeyEnabled || isDynamicWaasEnabled,
            disabled,
            id: 'turnkey-provider',
            isSaved: isTurnkeySettingSaved,
            onChange: handleToggleChange,
            type: 'toggle',
          }}
          onCardClick={
            isTurnkeyEnabled ? () => handleShowModal(true) : undefined
          }
          Tags={
            isTurnkeyEnabled
              ? [
                  {
                    Tag: (
                      <ChainBadge enabledChains={embeddedWalletEnabledChains} />
                    ),
                    id: 'embeddedWalletEnabledChains',
                  },
                ]
              : undefined
          }
          RightSideTags={
            isTurnkeyEnabled
              ? [
                  {
                    Tag: (
                      <ChangedSettingsIndicator
                        amount={embeddedWalletEnabledChains.length}
                      />
                    ),
                    id: 'enabled',
                  },
                ]
              : undefined
          }
          Icon={<EmbeddedWalletIcon />}
          customActionIcon={{
            Icon: <AdminIcon />,
            className: styles['action-icon'],
          }}
        />
      </Section>

      <SideDrawer
        title={t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.title',
        )}
        isOpen={showModal && isTurnkeyEnabled}
        handleClose={() => handleShowModal(false)}
        Icon={<EmbeddedWalletIcon />}
        className={styles.modal}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.docs',
          ),
          url: DOCS_URL,
        }}
      >
        <div className={styles.drawer}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' color='gray-1'>
              {t(
                'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.chains',
              )}
            </Typography>

            <EmbeddedWalletChainToggle
              chainName={EmbeddedWalletSupportedChainEnum.Evm}
            />

            <EmbeddedWalletChainToggle
              chainName={EmbeddedWalletSupportedChainEnum.Sol}
            />
          </div>
          {enabledChains && enabledChains.length > 0 && (
            <div className={styles.section}>
              <Typography variant='paragraph-3' color='gray-1'>
                {t(
                  'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.default_chain',
                )}
              </Typography>

              <DefaultChainDropdown disabled={disabled} />
            </div>
          )}
          {enableV3EmbeddedWallets && (
            <div className={styles.section}>
              <Typography variant='paragraph-3' color='gray-1'>
                {t(
                  'v2.page.embedded_wallets.dynamic_section.embedded_wallet.default_wallet_version',
                )}
              </Typography>
              <Typography variant='paragraph-2' color='gray-1'>
                {t(
                  'v2.page.embedded_wallets.dynamic_section.embedded_wallet.default_wallet_version_description',
                )}
              </Typography>

              <DefaultWalletVersionDropdown disabled={disabled} />
            </div>
          )}
        </div>
      </SideDrawer>
    </>
  );
};
