/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ThresholdSignatureScheme {
    TwoOfTwo = 'TWO_OF_TWO',
    TwoOfThree = 'TWO_OF_THREE',
    ThreeOfFive = 'THREE_OF_FIVE'
}

export function ThresholdSignatureSchemeFromJSON(json: any): ThresholdSignatureScheme {
    return ThresholdSignatureSchemeFromJSONTyped(json, false);
}

export function ThresholdSignatureSchemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThresholdSignatureScheme {
    return json as ThresholdSignatureScheme;
}

export function ThresholdSignatureSchemeToJSON(value?: ThresholdSignatureScheme | null): any {
    return value as any;
}

