import { FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  CustomHostname,
  CustomHostnameStatusEnum,
  CustomHostnameVerificationRecord,
} from '@dynamic-labs/sdk-api';
import {
  Alert,
  Button,
  CopyIcon,
  SingleToggleCard,
  Table,
  Typography,
} from '@dynamic-labs/northstar';
import { useDeleteCustomHostname } from '@dynamic-labs/redcoast-query';

import { useEnvironmentId } from '../../../../../app/routes/Configurations/Providers/hooks';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog';

import styles from './DomainInfo.module.scss';

type DomainInfoProps = {
  hostname: CustomHostname;
  isRefetching: boolean;
  refetch: () => void;
};

export const DomainInfo: FC<DomainInfoProps> = ({
  hostname,
  isRefetching,
  refetch,
}) => {
  const { t } = useTranslation();

  const environmentId = useEnvironmentId();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showVerificationAlert, setShowVerificationAlert] = useState(false);
  const isVerifying = useRef(false);

  const {
    mutateAsync: deleteCustomHostnames,
    isLoading: isLoadingDeleteCustomHostnames,
  } = useDeleteCustomHostname({
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (!isRefetching) {
      if (isVerifying.current) {
        setShowVerificationAlert(true);
      }
      isVerifying.current = false;
    }
  }, [isRefetching]);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleVerifyDomain = () => {
    isVerifying.current = true;
    refetch();
  };

  const handleDeleteDomain = async () => {
    await deleteCustomHostnames({
      customHostnameId: hostname.id,
      environmentId: environmentId || '',
    });
  };

  const isVerified = hostname.status === CustomHostnameStatusEnum.Active;

  const tableHeadingType = t('domains.domain_info.table.type_header');
  const tableHeadingName = t('domains.domain_info.table.name_header');
  const tableHeadingValue = t('domains.domain_info.table.value_header');

  const tableItems =
    hostname.verificationRecords.map(
      (record: CustomHostnameVerificationRecord, index: number) => ({
        key: `${hostname.hostname}-${index}`,
        [tableHeadingName]: (
          <button
            className={styles.copyTextWrapper}
            type='button'
            onClick={() => copyText(record.name ?? '')}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {record.name}
            </Typography>
            <CopyIcon className={styles.copy} />
          </button>
        ),
        [tableHeadingType]: (
          <Typography variant='paragraph-1' weight='medium' color='gray-1'>
            {record.dnsRecordType}
          </Typography>
        ),
        [tableHeadingValue]: (
          <button
            className={styles.copyTextWrapper}
            type='button'
            onClick={() => copyText(record.value ?? '')}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {record.value}
            </Typography>
            <CopyIcon className={styles.copy} />
          </button>
        ),
      }),
    ) ?? [];

  return (
    <SingleToggleCard
      accordionKey={`${hostname.usage}-domain-info`}
      title={hostname.hostname}
      description={
        isVerified ? undefined : t('domains.domain_info.description')
      }
      defaultExpanded
      collapsible={isVerified}
      dontSeparateContentFromHeader
    >
      <div className={styles.content}>
        {showVerificationAlert &&
          (isVerified ? (
            <Alert
              title={t('domains.domain_info.verification_alert.success.title')}
              description={t(
                'domains.domain_info.verification_alert.success.description',
              )}
              variant='success'
            />
          ) : (
            <Alert
              title={t('domains.domain_info.verification_alert.error.title')}
              description={t(
                'domains.domain_info.verification_alert.error.description',
              )}
              variant='warning'
            />
          ))}
        <Table
          columnsAndProportions={[
            { label: tableHeadingType, proportion: 0.15 },
            { label: tableHeadingName, proportion: 0.425 },
            { label: tableHeadingValue, proportion: 0.425 },
          ]}
          items={tableItems}
          rowHeight={40}
          headerTextVariant='paragraph-1'
          headerColorVariant='dark'
          className={styles.table}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            text={t('domains.domain_info.delete_button_label')}
            onClick={() => setShowDeleteConfirmation(true)}
            variant='warning-secondary'
            size='large'
            isLoading={isLoadingDeleteCustomHostnames}
          />
          {!isVerified && (
            <Button
              text={t('domains.domain_info.verify_button_label')}
              onClick={handleVerifyDomain}
              variant='primary'
              size='large'
              isLoading={isLoadingDeleteCustomHostnames || isRefetching}
            />
          )}
        </div>
      </div>
      <DeleteConfirmationDialog
        open={showDeleteConfirmation}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDeleteDomain}
      />
    </SingleToggleCard>
  );
};
