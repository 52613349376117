import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useMutation } from 'react-query';

import { EnvironmentEnum } from '@dynamic-labs/sdk-api-core';

import { OnrampProvidersContextProvider } from '../../../app/context/OnrampProvidersContext';
import { PageHeader, SaveBanner } from '../../components';
import { ROUTES } from '../../../app/components/Navigation/data';
import { useSettingsContext } from '../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { useEnvironmentId } from '../../../app/routes/Configurations/Providers/hooks';
import { useSettingsHasChanged } from '../../../app/hooks/useSettingsHasChanged';
import { environmentsApi } from '../../../app/services/api';

import styles from './FundingPage.module.scss';
import { OnrampProviders, ExternalFundingSection } from './Sections';
import { didSettingsChange } from './utils';

export const FundingPage: FC = () => {
  const { t } = useTranslation();
  const { settings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const environmentId = useEnvironmentId();
  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
  } = useSettingsHasChanged('sdk');
  const { enableExternalWalletFunding } = useFlags();

  const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
    useMutation(
      async () =>
        environmentId &&
        environmentsApi.updateProjectSettings({
          environmentId,
          projectSettings: settings[activeEnvironmentType],
        }),
      {
        onSuccess: () => {
          updateProjectInitialSettings(
            activeEnvironmentType as EnvironmentEnum,
          );
        },
      },
    );

  if (!enableExternalWalletFunding) {
    return <Navigate to={ROUTES.dashboard} />;
  }

  const settingsChanged = didSettingsChange(
    settings[activeEnvironmentType].sdk.funding,
    initialSettings[activeEnvironmentType].sdk.funding,
  );

  return (
    <OnrampProvidersContextProvider>
      <section className={styles.container}>
        <PageHeader
          title={t('v2.page.funding.title')}
          description={t('v2.page.funding.description')}
        />
        <ExternalFundingSection />
        <OnrampProviders />
      </section>
      <AnimatePresence mode='wait'>
        {settingsChanged && (
          <SaveBanner
            handleOnCancel={cancelProjectChanges}
            handleOnSave={saveProjectSettings}
            isLoading={isSavingProjectSettings}
          />
        )}
      </AnimatePresence>
    </OnrampProvidersContextProvider>
  );
};
