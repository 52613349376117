import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { accessControlData, onrampData } from '../../data/integrations';
import { Typography } from '../../components/Typography';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { UpsellBanner } from '../../components/UpsellBanner';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';

import styles from './configurations.module.css';
import { AccessControl } from './AccessControl';
import { AccountAbstractionCard } from './AccountAbstraction';
import { OnrampCard } from './Onramp';

const ConfigurationsRoute = () => {
  const { t } = useTranslation();

  const {
    enableWalletConnectDashboardIntegration,
    enableAdvancedWalletOptionsDashboardIntegration,
    enableOnrampFundingIntegration,
    enableExternalWalletFunding,
    enableAccountAbstractionIntegration,
  } = useFlags();

  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const { trialDaysLeft } = subscription || {};

  return (
    <section className='pb-6'>
      <div className='w-fit flex flex-col gap-6'>
        {shouldShowTrialBanner && (
          <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
        )}

        {(enableWalletConnectDashboardIntegration ||
          enableAdvancedWalletOptionsDashboardIntegration ||
          enableOnrampFundingIntegration) && (
          <div className={styles.container}>
            <div className={styles.wallets__container}>
              {enableAccountAbstractionIntegration && (
                <AccountAbstractionCard />
              )}
            </div>
          </div>
        )}

        <div className={styles.container}>
          <Typography variant='paragraph-1' className={styles.heading}>
            {t('integrations.onboarding_and_kyc.title')}
          </Typography>
          <div className={styles.chains__container}>
            <AccessControl
              title={accessControlData.title}
              key={accessControlData.id}
              name={accessControlData.name}
              content={accessControlData.content}
              Icon={accessControlData.Icon}
              scope={accessControlData.scope}
            />
          </div>
        </div>

        <div className={styles.container}>
          {!enableExternalWalletFunding && (
            <OnrampCard
              Icon={onrampData.Icon}
              title={onrampData.title}
              content={onrampData.content}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default ConfigurationsRoute;
