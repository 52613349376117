import { FC, PropsWithChildren } from 'react';

import { Typography } from '@dynamic-labs/northstar';

import styles from './ConfigItem.module.scss';

type ConfigItemProps = PropsWithChildren<{
  description?: string;
  title: string;
}>;

export const ConfigItem: FC<ConfigItemProps> = ({
  title,
  description,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography as='p' variant='paragraph-2' weight='bold' color='black-2'>
        {title}
      </Typography>
      {description && (
        <Typography
          as='p'
          variant='paragraph-1'
          weight='regular'
          color='black-2'
        >
          {description}
        </Typography>
      )}
    </div>
    {children}
  </div>
);
