/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportWaasWalletPrivateKeyRequest
 */
export interface ExportWaasWalletPrivateKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportWaasWalletPrivateKeyRequest
     */
    exportId: string;
}

export function ExportWaasWalletPrivateKeyRequestFromJSON(json: any): ExportWaasWalletPrivateKeyRequest {
    return ExportWaasWalletPrivateKeyRequestFromJSONTyped(json, false);
}

export function ExportWaasWalletPrivateKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportWaasWalletPrivateKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportId': json['exportId'],
    };
}

export function ExportWaasWalletPrivateKeyRequestToJSON(value?: ExportWaasWalletPrivateKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportId': value.exportId,
    };
}

