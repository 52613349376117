import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { pxToRem } from '../../helpers/pxToRem';
import { ClassStyleProps } from '../../utils/types';
import { Typography, TypographyVariant } from '../Typography';

import styles from './Table.module.scss';

export type RowClickAction = (index: number) => void;

export type TableProps<T extends string> = ClassStyleProps & {
  /**
   * Displays an icon on the right of each row when the row is hovered.
   * This takes up a fixed amount of pixels to the right of the rows.
   */
  action?: { icon: ReactNode; onClick: RowClickAction };
  /**
   * Defines the names of the columns and which proportion of the width they take
   */
  columnsAndProportions: { label: T; proportion: number }[];
  /**
   * Text to show when table is empty (items is an empty array)
   */
  emptyLabel?: string;
  /**
   * Variant of the header background color
   */
  headerColorVariant?: 'light' | 'dark';
  /**
   * Variant of the header text
   */
  headerTextVariant?: TypographyVariant;
  /**
   * Items that will be displayed in each row
   */
  items: (
    | (Record<T, ReactNode> & { key: string })
    | { key: string; override: ReactNode; overrideHeight?: number }
  )[];

  /**
   * Height of each row, in pixels.
   */
  rowHeight: number;
};

export const Table = <T extends string>({
  columnsAndProportions,
  items,
  rowHeight,
  className,
  style,
  emptyLabel,
  action,
  headerTextVariant,
  headerColorVariant = 'light',
}: TableProps<T>): ReturnType<FC<TableProps<T>>> => {
  const heightStyle = (
    item: Record<string, unknown> & { overrideHeight?: number } = {},
  ) =>
    pxToRem(
      'overrideHeight' in item ? item.overrideHeight ?? rowHeight : rowHeight,
    );

  return (
    <div className={classNames(styles.table, className)} style={style}>
      {items.length === 0 && (
        <Typography
          variant={headerTextVariant ?? 'paragraph-2'}
          color='gray-2'
          weight='medium'
          className={styles['empty-label']}
        >
          {emptyLabel}
        </Typography>
      )}

      {items.length > 0 && (
        <>
          <header
            className={classNames(
              styles.row,
              styles[`header-${headerColorVariant}`],
            )}
            style={{ height: heightStyle() }}
          >
            {columnsAndProportions.map(({ label, proportion }) => (
              <Typography
                variant={headerTextVariant ?? 'paragraph-2'}
                color='gray-1'
                weight='bold'
                className={styles.column}
                style={{ flex: proportion }}
                key={`${label}-${proportion}`}
              >
                {label}
              </Typography>
            ))}

            {/* An invisible action item on header to keep spacing consistent */}
            {action && (
              <div className={classNames(styles.action, styles.hidden)}>
                {action.icon}
              </div>
            )}
          </header>

          {items.map((item, index) => (
            <div
              className={styles.row}
              key={item.key}
              style={{ height: heightStyle(item) }}
            >
              {'override' in item ? (
                item.override
              ) : (
                <>
                  {columnsAndProportions.map(({ label, proportion }) => (
                    <div
                      key={`${label}-${proportion}`}
                      className={styles.column}
                      style={{ flex: proportion }}
                    >
                      {item[label]}
                    </div>
                  ))}

                  {action && (
                    <button
                      className={styles.action}
                      onClick={() => action.onClick(index)}
                      type='button'
                    >
                      {action.icon}
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
