import { useMutation, useQuery } from 'react-query';

import {
  GlobalWalletSettings,
  GlobalWalletSettingsCreateRequest,
  GlobalWalletSettingsUpdateRequest,
} from '@dynamic-labs/sdk-api';

import { globalWalletsApi } from '../../services/api';

export const useGlobalWallets = (environmentId: string, enabled = true) => {
  const queryKey = ['environments', environmentId, 'globalWallet'];

  const {
    data,
    isLoading,
    refetch,
    error: errorLoading,
  } = useQuery<GlobalWalletSettings | undefined>(
    queryKey,
    async () => {
      try {
        return await globalWalletsApi.getGlobalWalletSettings({
          environmentId,
        });
      } catch (e) {
        return undefined;
      }
    },
    {
      enabled: !!environmentId && enabled,
    },
  );

  const {
    mutateAsync: createGlobalWalletSettings,
    isLoading: isCreating,
    error: errorCreating,
  } = useMutation(
    (globalWalletSettingsCreateRequest: GlobalWalletSettingsCreateRequest) =>
      globalWalletsApi.createGlobalWalletSettings({
        environmentId,
        globalWalletSettingsCreateRequest,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const {
    mutateAsync: updateGlobalWalletSettings,
    isLoading: isUpdating,
    error: errorUpdating,
  } = useMutation(
    (globalWalletSettingsUpdateRequest: GlobalWalletSettingsUpdateRequest) =>
      globalWalletsApi.updateGlobalWalletSettings({
        environmentId,
        globalWalletSettingsUpdateRequest,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const {
    mutateAsync: enableGlobalWallet,
    isLoading: isEnabling,
    error: errorEnabling,
  } = useMutation(
    () =>
      globalWalletsApi.enableGlobalWallet({
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const {
    mutateAsync: disableGlobalWallet,
    isLoading: isDisabling,
    error: errorDisabling,
  } = useMutation(
    () =>
      globalWalletsApi.disableGlobalWallet({
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    createGlobalWalletSettings,
    disableGlobalWallet,
    enableGlobalWallet,
    error:
      errorLoading ||
      errorCreating ||
      errorUpdating ||
      errorEnabling ||
      errorDisabling,
    globalWalletSettings: data,
    isLoading,
    isSaving:
      isLoading || isCreating || isUpdating || isEnabling || isDisabling,
    refetch,
    updateGlobalWalletSettings,
  };
};
