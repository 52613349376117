import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Input, SingleToggleCard } from '@dynamic-labs/northstar';

import { ROUTES } from '../../../app/components/Navigation/data';

import styles from './ManageCustomDomain.module.scss';

type ManageCustomDomainProps = {
  description?: string;
  domain: string;
  onClickManageDomain?: () => void;
  title?: string;
};

export const ManageCustomDomain: FC<ManageCustomDomainProps> = ({
  domain,
  title,
  description,
  onClickManageDomain,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleManageDomain = () => {
    if (onClickManageDomain) {
      return onClickManageDomain();
    }

    navigate(ROUTES.developerDomains);
  };

  return (
    <SingleToggleCard
      accordionKey='manage-custom-domain'
      title={title ?? t('v2.component.manage_custom_domain.default_title')}
      description={
        description ??
        t('v2.component.manage_custom_domain.default_description')
      }
      defaultExpanded
      collapsible={false}
    >
      <div className={styles.content}>
        <Input name='domain' value={domain} readOnly className={styles.input} />
        <Button
          text={t(
            'v2.component.manage_custom_domain.manage_domain_button_label',
          )}
          onClick={handleManageDomain}
          variant='secondary'
          size='large'
          dataTestId='manage-domain-button'
        />
      </div>
    </SingleToggleCard>
  );
};
