import { FC, useCallback, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  ChevronRightIcon,
  Tag,
  SingleToggleCard,
} from '@dynamic-labs/northstar';

import { CustomAddressFieldValues, DOCS_URL } from '../util';
import { AdminIcon } from '../../../../../icons';
import { SideDrawer } from '../../../../components/SideDrawer';
import { SubdomainsDrawer } from '../SubdomainsDrawer';

import styles from './SubdomainsToggle.module.scss';

type SubdomainsToggleProps = {
  isNameServicesEnabled: boolean;
};

/** Toggle component for enabling personalized subdomains */
export const SubdomainsToggle: FC<SubdomainsToggleProps> = ({
  isNameServicesEnabled,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, initialValues } =
    useFormikContext<CustomAddressFieldValues>();

  const [showDrawer, setShowDrawer] = useState(false);

  const handleToggleChange = useCallback(() => {
    const newToggleValue = !values.subdomainsEnabled;
    setFieldValue('subdomainsEnabled', newToggleValue);
  }, [setFieldValue, values]);

  const onSettingsClick = useCallback(() => {
    setShowDrawer(true);
  }, []);

  /** Closing the drawer without setting ENS domain should reset toggle */
  const onDrawerClose = useCallback(() => {
    setShowDrawer(false);
  }, []);

  /** Tags for the toggle to render */
  const tags = useMemo(() => {
    if (!isNameServicesEnabled && values.subdomainsEnabled) {
      return [
        {
          Tag: (
            <Tag
              icon={<ChevronRightIcon />}
              iconPosition='right'
              text={t('common.tags.needs_configuration')}
              color='gray'
              size='large'
            />
          ),
          id: 'needsConfiguration',
        },
      ];
    }

    return undefined;
  }, [isNameServicesEnabled, t, values]);

  return (
    <>
      <SingleToggleCard
        accordionKey='subdomains'
        title={t('v2.page.global_wallets.subdomains.toggle.title')}
        allowExpand={false}
        onCardClick={values.subdomainsEnabled ? onSettingsClick : undefined}
        inputProps={{
          checked: values.subdomainsEnabled,
          id: 'subdomainsToggle',
          isSaved: values.subdomainsEnabled === initialValues.subdomainsEnabled,
          isWarning: values.subdomainsEnabled && !isNameServicesEnabled,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        RightSideTags={tags}
      />
      <SideDrawer
        handleClose={onDrawerClose}
        isOpen={showDrawer}
        title={t('v2.page.global_wallets.subdomains.title')}
        description={t('v2.page.global_wallets.subdomains.drawer.description')}
        docs={{
          text: t('v2.page.global_wallets.subdomains.learn_more'),
          url: DOCS_URL,
        }}
      >
        <SubdomainsDrawer />
      </SideDrawer>
    </>
  );
};
