import React, { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard, Typography } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { AdminIcon, EmbeddedWalletIcon } from '../../../../../../../icons';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';

import styles from './OnSignupToggle.module.scss';
import { ThirdPartyEmbeddedWalletToggle } from './ThirdPartyEmbeddedWalletToggle';

const DOCS_URL =
  'https://docs.dynamic.xyz/wallets/embedded-wallets/create-embedded-wallets#during-signup-automatic';

type OnSignupToggleProps = {
  disabled: boolean;
};

export const OnSignupToggle: FC<OnSignupToggleProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.EmbeddedWalletsSettings,
  });

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isOnSignUpEnabled = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreation &&
      isTurnkeyEnabled &&
      !disabled,
  );

  const isOnSignUpSettingSaved = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreation ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreation,
  );

  const handleOnSignupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            automaticEmbeddedWalletCreation: event.target.checked,
          },
        },
      },
    });
  };

  return (
    <>
      <SingleToggleCard
        allowExpand={false}
        title={t(
          'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.title',
        )}
        description={t(
          'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.description',
        )}
        accordionKey='automatic_creation'
        inputProps={{
          checked: isTurnkeyEnabled && isOnSignUpEnabled,
          disabled: !isTurnkeyEnabled || disabled,
          id: 'automatic_creation',
          isSaved: isTurnkeySettingSaved && isOnSignUpSettingSaved,
          onChange: handleOnSignupChange,
          type: 'toggle',
        }}
        onCardClick={
          isTurnkeyEnabled && isOnSignUpEnabled
            ? () => handleShowModal(!showModal)
            : undefined
        }
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        title={t(
          'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.title',
        )}
        isOpen={showModal && isTurnkeyEnabled && isOnSignUpEnabled}
        handleClose={() => handleShowModal(false)}
        Icon={<EmbeddedWalletIcon />}
        className={styles.modal}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.docs',
          ),
          url: DOCS_URL,
        }}
      >
        <div className={styles.drawer}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' color='gray-1'>
              {t(
                'v2.page.embedded_wallets.dynamic_section.settings.automatic_creation.advanced_settings.title',
              )}
            </Typography>

            <ThirdPartyEmbeddedWalletToggle disabled={disabled} />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
