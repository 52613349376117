import { EnvironmentSettings } from '../../../../app/context/SettingsContext';

export const didSettingsChange = (
  initialExternalFundingSettings: EnvironmentSettings['live']['sdk']['funding'],
  settings: EnvironmentSettings['live']['sdk']['funding'],
) =>
  initialExternalFundingSettings?.externalWallets?.enabled !==
    settings?.externalWallets?.enabled ||
  initialExternalFundingSettings?.externalWallets?.minAmount?.amount !==
    settings?.externalWallets?.minAmount?.amount ||
  initialExternalFundingSettings?.externalWallets?.minAmount?.currency !==
    settings?.externalWallets?.minAmount?.currency;
