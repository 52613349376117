import { ChangeEvent, FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Icon,
  Input,
  Section,
  SingleToggleCard,
} from '@dynamic-labs/northstar';

import { AdminIcon } from '../../../../../icons';
import { SideDrawer } from '../../../../components/SideDrawer';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { DropdownField } from '../../../../../app/components/DropdownField';

export const ExternalFundingSection: FC = () => {
  const { t } = useTranslation();
  const [showExternalFundingDrawer, setShowExternalFundingDrawer] =
    useState(false);

  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isExternalWalletFundingEnabled = Boolean(
    settings[activeEnvironmentType].sdk?.funding?.externalWallets?.enabled,
  );

  const isSettingSaved = Boolean(
    initialSettings[activeEnvironmentType].sdk?.funding?.externalWallets
      ?.enabled === isExternalWalletFundingEnabled,
  );

  const [amount, setAmount] = useState(
    settings[activeEnvironmentType].sdk.funding?.externalWallets?.minAmount
      ?.amount,
  );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          funding: {
            ...settings[activeEnvironmentType].sdk.funding,
            externalWallets: {
              ...settings[activeEnvironmentType].sdk.funding?.externalWallets,
              enabled: e.target.checked,
            },
          },
        },
      },
    });
  };

  const handleAmountChange = () => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          funding: {
            ...settings[activeEnvironmentType].sdk.funding,
            externalWallets: {
              ...settings[activeEnvironmentType].sdk.funding?.externalWallets,
              minAmount: {
                ...settings[activeEnvironmentType].sdk.funding?.externalWallets
                  ?.minAmount,
                amount,
              },
            },
          },
        },
      },
    });
  };

  const handleCurrencyChange = (currency: string) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          funding: {
            ...settings[activeEnvironmentType].sdk.funding,
            externalWallets: {
              ...settings[activeEnvironmentType].sdk.funding?.externalWallets,
              minAmount: {
                ...settings[activeEnvironmentType].sdk.funding?.externalWallets
                  ?.minAmount,
                currency,
              },
            },
          },
        },
      },
    });
  };

  const currencyOptions = [
    {
      label: 'ETH',
      value: 'ETH',
    },
    {
      label: 'USDC',
      value: 'USDC',
    },
    {
      label: 'USDT',
      value: 'USDT',
    },
    {
      label: 'SOL',
      value: 'SOL',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Section
        title={t('v2.page.funding.sections.external_funding.title')}
        description={t('v2.page.funding.sections.external_funding.description')}
      >
        <SingleToggleCard
          accordionKey='wallets-enabled'
          title={t('v2.page.funding.sections.external_funding.title')}
          allowExpand={false}
          data-testid='external-wallets-toggle'
          onCardClick={() => setShowExternalFundingDrawer(true)}
          customActionIcon={{
            Icon: <Icon icon={<AdminIcon />} color='primary-1' />,
          }}
          inputProps={{
            checked: isExternalWalletFundingEnabled,
            id: 'external-wallets-enabled',
            isSaved: isSettingSaved,
            onChange: handleToggle,
            type: 'toggle',
          }}
        />
      </Section>
      <SideDrawer
        handleClose={() => setShowExternalFundingDrawer(false)}
        isOpen={showExternalFundingDrawer}
        title='External Wallets'
        docs={{
          text: 'Learn more',
          url: 'https://docs.dynamic.xyz/fiat-onboarding/banxa#banxa',
        }}
      >
        <div className='flex flex-col gap-1'>
          <div className='text-sm font-bold'>
            {t(
              'v2.page.funding.sections.external_funding.customizations.amount_and_currency.title',
            )}
          </div>
          <div className='text-xs font-normal'>
            {t(
              'v2.page.funding.sections.external_funding.customizations.amount_and_currency.description',
            )}
          </div>
          <div className='flex flex-row gap-2'>
            <Input
              name='amount'
              label={t(
                'v2.page.funding.sections.external_funding.customizations.amount_and_currency.inputs.minAmount.amountPlaceholder',
              )}
              className='bg-[#555A7C08]'
              onChange={(e) => setAmount(e.target.value)}
              onBlur={handleAmountChange}
              value={amount}
            />
            <DropdownField
              className='bg-[#555A7C08]'
              onSelect={handleCurrencyChange}
              value={
                settings[activeEnvironmentType].sdk.funding?.externalWallets
                  ?.minAmount?.currency ?? 'eth'
              }
              placeholder={t(
                'v2.page.funding.sections.external_funding.customizations.amount_and_currency.inputs.minAmount.amountPlaceholder',
              )}
              testId='external-wallets-currency-dropdown'
              options={currencyOptions}
            />
          </div>
        </div>
      </SideDrawer>
    </div>
  );
};
