/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainConfiguration,
    ChainConfigurationFromJSON,
    ChainConfigurationFromJSONTyped,
    ChainConfigurationToJSON,
} from './ChainConfiguration';
import {
    Duration,
    DurationFromJSON,
    DurationFromJSONTyped,
    DurationToJSON,
} from './Duration';
import {
    EmbeddedWalletVersionEnum,
    EmbeddedWalletVersionEnumFromJSON,
    EmbeddedWalletVersionEnumFromJSONTyped,
    EmbeddedWalletVersionEnumToJSON,
} from './EmbeddedWalletVersionEnum';
import {
    SupportedSecurityMethods,
    SupportedSecurityMethodsFromJSON,
    SupportedSecurityMethodsFromJSONTyped,
    SupportedSecurityMethodsToJSON,
} from './SupportedSecurityMethods';

/**
 * 
 * @export
 * @interface ProjectSettingsSdkEmbeddedWallets
 */
export interface ProjectSettingsSdkEmbeddedWallets {
    /**
     * When true embedded wallets will be generated during onboarding for the users. When false customer needs to trigger the creation.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    automaticEmbeddedWalletCreation?: boolean;
    /**
     * When true embedded wallets will be created for external wallets during sign in. When false embedded wallets are not generated for external wallets during sign in.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    automaticEmbeddedWalletCreationForExternal?: boolean;
    /**
     * When true users will see embedded wallets action confirmation views.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    showEmbeddedWalletActionsUI?: boolean;
    /**
     * When true users will be able to start recovery for their accounts.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    emailRecoveryEnabled?: boolean;
    /**
     * When true user will be prompted to add a security method during onboarding. When false user will need to add a security method before a transaction
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    forceAuthenticatorAtSignup?: boolean;
    /**
     * When combined with forceAuthenticatorAtSignup it allows user to skip adding a security method during onboarding. User will need to add it before a transaction.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    allowSkippingAuthenticatorAtSignup?: boolean;
    /**
     * 
     * @type {Duration}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    sessionKeyDuration?: Duration;
    /**
     * 
     * @type {SupportedSecurityMethods}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    supportedSecurityMethods?: SupportedSecurityMethods;
    /**
     * Chain specific configuration for dynamic embedded wallets
     * @type {Array<ChainConfiguration>}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    chainConfigurations?: Array<ChainConfiguration>;
    /**
     * When a client domain needs to be whitelisted explicitly by one of our embedded wallet providers, this will be true when Dynamic has confirmed that this is the case.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    domainEnabledByProvider?: boolean;
    /**
     * 
     * @type {EmbeddedWalletVersionEnum}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    defaultWalletVersion?: EmbeddedWalletVersionEnum;
    /**
     * When true, the user will be prompted to export their private key after creating a wallet.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    promptForKeyExport?: boolean;
    /**
     * When true, user transactions will show asset transfers in the Confirmation UI.
     * @type {boolean}
     * @memberof ProjectSettingsSdkEmbeddedWallets
     */
    transactionSimulation?: boolean;
}

export function ProjectSettingsSdkEmbeddedWalletsFromJSON(json: any): ProjectSettingsSdkEmbeddedWallets {
    return ProjectSettingsSdkEmbeddedWalletsFromJSONTyped(json, false);
}

export function ProjectSettingsSdkEmbeddedWalletsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkEmbeddedWallets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automaticEmbeddedWalletCreation': !exists(json, 'automaticEmbeddedWalletCreation') ? undefined : json['automaticEmbeddedWalletCreation'],
        'automaticEmbeddedWalletCreationForExternal': !exists(json, 'automaticEmbeddedWalletCreationForExternal') ? undefined : json['automaticEmbeddedWalletCreationForExternal'],
        'showEmbeddedWalletActionsUI': !exists(json, 'showEmbeddedWalletActionsUI') ? undefined : json['showEmbeddedWalletActionsUI'],
        'emailRecoveryEnabled': !exists(json, 'emailRecoveryEnabled') ? undefined : json['emailRecoveryEnabled'],
        'forceAuthenticatorAtSignup': !exists(json, 'forceAuthenticatorAtSignup') ? undefined : json['forceAuthenticatorAtSignup'],
        'allowSkippingAuthenticatorAtSignup': !exists(json, 'allowSkippingAuthenticatorAtSignup') ? undefined : json['allowSkippingAuthenticatorAtSignup'],
        'sessionKeyDuration': !exists(json, 'sessionKeyDuration') ? undefined : DurationFromJSON(json['sessionKeyDuration']),
        'supportedSecurityMethods': !exists(json, 'supportedSecurityMethods') ? undefined : SupportedSecurityMethodsFromJSON(json['supportedSecurityMethods']),
        'chainConfigurations': !exists(json, 'chainConfigurations') ? undefined : ((json['chainConfigurations'] as Array<any>).map(ChainConfigurationFromJSON)),
        'domainEnabledByProvider': !exists(json, 'domainEnabledByProvider') ? undefined : json['domainEnabledByProvider'],
        'defaultWalletVersion': !exists(json, 'defaultWalletVersion') ? undefined : EmbeddedWalletVersionEnumFromJSON(json['defaultWalletVersion']),
        'promptForKeyExport': !exists(json, 'promptForKeyExport') ? undefined : json['promptForKeyExport'],
        'transactionSimulation': !exists(json, 'transactionSimulation') ? undefined : json['transactionSimulation'],
    };
}

export function ProjectSettingsSdkEmbeddedWalletsToJSON(value?: ProjectSettingsSdkEmbeddedWallets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automaticEmbeddedWalletCreation': value.automaticEmbeddedWalletCreation,
        'automaticEmbeddedWalletCreationForExternal': value.automaticEmbeddedWalletCreationForExternal,
        'showEmbeddedWalletActionsUI': value.showEmbeddedWalletActionsUI,
        'emailRecoveryEnabled': value.emailRecoveryEnabled,
        'forceAuthenticatorAtSignup': value.forceAuthenticatorAtSignup,
        'allowSkippingAuthenticatorAtSignup': value.allowSkippingAuthenticatorAtSignup,
        'sessionKeyDuration': DurationToJSON(value.sessionKeyDuration),
        'supportedSecurityMethods': SupportedSecurityMethodsToJSON(value.supportedSecurityMethods),
        'chainConfigurations': value.chainConfigurations === undefined ? undefined : ((value.chainConfigurations as Array<any>).map(ChainConfigurationToJSON)),
        'domainEnabledByProvider': value.domainEnabledByProvider,
        'defaultWalletVersion': EmbeddedWalletVersionEnumToJSON(value.defaultWalletVersion),
        'promptForKeyExport': value.promptForKeyExport,
        'transactionSimulation': value.transactionSimulation,
    };
}

