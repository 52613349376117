import { ButtonHTMLAttributes, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ClassStyleProps } from '../../utils/types';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';

import styles from './Button.module.scss';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'primary' | 'secondary' | 'warning' | 'warning-secondary';

type ButtonProps = ClassStyleProps & {
  dataTestId?: string;
  disabled?: boolean;
  icon?: ReactElement;
  isLoading?: boolean;
  onClick?: VoidFunction;
  size?: ButtonSize;
  text: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: ButtonVariant;
};

export const Button: FC<ButtonProps> = ({
  text,
  onClick,
  dataTestId,
  disabled,
  className,
  icon: ButtonIcon,
  isLoading = false,
  size = 'small',
  variant = 'primary',
  type = 'button',
  style,
}) => {
  const iconSize = size === 'medium' ? 16 : 20;
  const typographyVariant =
    size === 'medium' ? 'button-small' : 'button-medium';
  // there's a weird lint bug where it doesn't recognize the button type is defined
  /* eslint-disable react/button-has-type */
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classNames(
        styles.button,
        styles[`button--${size}`],
        styles[`button--${variant}`],
        {
          [styles.disabled]: disabled,
          [styles['button--icon']]: Boolean(ButtonIcon),
        },
        className,
      )}
      onClick={() => onClick?.()}
      data-testid={dataTestId}
      style={style}
    >
      {ButtonIcon && !isLoading && (
        <Icon
          icon={ButtonIcon}
          color={disabled ? 'gray-2' : undefined}
          size={iconSize}
        />
      )}
      {isLoading && <Spinner color='gray-2' size={iconSize} />}
      <Typography variant={typographyVariant} color='inherit'>
        {text}
      </Typography>
    </button>
  );
};
