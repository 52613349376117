import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Alert } from '@dynamic-labs/northstar';

import { PageHeader } from '../../components';

import styles from './Domains.module.scss';
import { Sections } from './Sections';

/** Component for Domains Page */
export const Domains = () => {
  const { t } = useTranslation();
  const { enableCustomDomainDashboard, enableGlobalWalletPopup } = useFlags();

  // enableCustomDomainDashboard is used for cookies at the moment
  const disableCustomDomains =
    !enableCustomDomainDashboard && !enableGlobalWalletPopup;

  return (
    <section className={styles.wrapper} data-testid='domains'>
      <div className={styles.container}>
        <PageHeader
          title={t('domains.heading')}
          description={t('domains.description')}
        />
        {disableCustomDomains ? (
          <Alert
            variant='info'
            title={t('domains.alert.title')}
            description={t('domains.alert.description')}
          />
        ) : (
          <Sections />
        )}
      </div>
    </section>
  );
};
