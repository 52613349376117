import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard, Typography } from '@dynamic-labs/northstar';
import { ProviderEnum, TimeUnitEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../../../../app/context/SettingsContext';
import { useProvidersContext } from '../../../../../../../../../../app/context/ProvidersContext';
import {
  MAX_VALUES_PER_UNIT,
  TimeInput,
} from '../../../../../../../../Security/TimeInput';
import { TimeTypeDropDown } from '../../../../../../../../Security/TimeTypeDropDown';

import styles from './PromptFrequency.module.scss';

type PromptFrequencyProps = {
  authenticatorEnabled: boolean;
  disabled: boolean;
};

const DEFAULT_AMOUNT = 30;
const DEFAULT_UNIT = TimeUnitEnum.Minutes;
const AVAILABLE_UNITS = [TimeUnitEnum.Minutes, TimeUnitEnum.Hours];

export const PromptFrequency: FC<PromptFrequencyProps> = ({
  disabled,
  authenticatorEnabled,
}) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings } = useSettingsContext();
  const { getProviderValue } = useProvidersContext();

  const savedSessionKeyValue =
    settings[activeEnvironmentType]?.sdk.embeddedWallets?.sessionKeyDuration;

  const [frequencyAmount, setFrequencyAmount] = useState<number | ''>(
    savedSessionKeyValue?.amount || DEFAULT_AMOUNT,
  );
  const savedTimeUnit = savedSessionKeyValue?.unit || DEFAULT_UNIT;

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const handleChangeValue = (amount: number | '', unit: TimeUnitEnum) => {
    // If values are empty use default ones
    const timeUnitToSave = amount === '' ? TimeUnitEnum.Minutes : unit;
    const amountToSave = amount === '' ? DEFAULT_AMOUNT : amount;

    // Don't change values if they are the same
    if (
      amountToSave === savedSessionKeyValue?.amount &&
      timeUnitToSave === savedSessionKeyValue?.unit
    ) {
      return;
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType]?.sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            sessionKeyDuration: {
              amount:
                // if amount is bigger than the max value for the unit, set to max value (i.e. 30h)
                amountToSave > MAX_VALUES_PER_UNIT[timeUnitToSave]
                  ? MAX_VALUES_PER_UNIT[timeUnitToSave]
                  : amountToSave,
              unit: timeUnitToSave,
            },
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      allowExpand
      title={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.prompt_frequency.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.prompt_frequency.description',
      )}
      accordionKey='prompt-frequency'
      defaultExpanded
    >
      <div className={styles.container}>
        <Typography variant='paragraph-2' color='gray-1' weight='regular'>
          {t(
            'v2.page.embedded_wallets.dynamic_section.mfa.method.email.drawer.refresh',
          )}
        </Typography>

        <div className={styles.inputs}>
          <TimeInput
            amount={frequencyAmount}
            setAmount={setFrequencyAmount}
            unit={savedTimeUnit}
            onBlur={() => handleChangeValue(frequencyAmount, savedTimeUnit)}
            disabled={!isTurnkeyEnabled || !authenticatorEnabled || disabled}
            className={styles.amount}
          />
          <TimeTypeDropDown
            unit={savedTimeUnit}
            setUnit={(value) => handleChangeValue(frequencyAmount, value)}
            values={AVAILABLE_UNITS}
            disabled={!isTurnkeyEnabled || !authenticatorEnabled || disabled}
            className={styles.unit}
          />
        </div>
      </div>
    </SingleToggleCard>
  );
};
