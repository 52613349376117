/* eslint-disable import/no-mutable-exports */
import {
  AllowlistsApi,
  AnalyticsApi,
  ChainalysisApi,
  ChainsApi,
  Configuration,
  DeeplinkUrlsApi,
  EnvironmentsApi,
  EventsApi,
  ExportsApi,
  ExternalJwtApi,
  FetchParams,
  GatesApi,
  InvitesApi,
  MembersApi,
  MfaApi,
  MfaSettingsApi,
  Middleware,
  NameServicesApi,
  OrganizationsApi,
  OriginsApi,
  ProjectsApi,
  ResponseContext,
  SettingsApi,
  TestAccountApi,
  TokensApi,
  UsersApi,
  VisitsApi,
  WebhooksApi,
  GlobalWalletsApi,
} from '@dynamic-labs/sdk-api';
import { getAuthToken } from '@dynamic-labs/sdk-react-core';

import environment from '../../environments/environment';

const defaultBaseUrl = environment.isDeployed ? '/api/v0' : undefined;

export const baseUrl: string | undefined =
  process.env.NX_BUILD_DYNAMIC_API_BASE_URL ?? defaultBaseUrl;

class BearerAuthTokenMiddleware implements Middleware {
  pre(context: ResponseContext): Promise<FetchParams | void> {
    const fetchParams = {
      init: {
        ...context.init,
        headers: {
          ...context.init.headers,
          Authorization: `Bearer ${getAuthToken()}`,
        },
      },
      url: context.url,
    };
    return Promise.resolve(fetchParams);
  }

  post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response);
  }
}

const configuration = new Configuration({
  basePath: baseUrl,
  middleware: [new BearerAuthTokenMiddleware()],
});
export const allowListsApi = new AllowlistsApi(configuration);
export const analyticsApi = new AnalyticsApi(configuration);
export const chainalysisApi = new ChainalysisApi(configuration);
export const chainsApi = new ChainsApi(configuration);
export const deeplinkUrlsApi = new DeeplinkUrlsApi(configuration);
export const environmentsApi = new EnvironmentsApi(configuration);
export const eventsApi = new EventsApi(configuration);
export const exportsApi = new ExportsApi(configuration);
export const externalJwtApi = new ExternalJwtApi(configuration);
export const gatesApi = new GatesApi(configuration);
export const globalWalletsApi = new GlobalWalletsApi(configuration);
export const invitesApi = new InvitesApi(configuration);
export const membersApi = new MembersApi(configuration);
export const mfaApi = new MfaApi(configuration);
export const mfaSettingsApi = new MfaSettingsApi(configuration);
export const nameServicesApi = new NameServicesApi(configuration);
export const organizationsApi = new OrganizationsApi(configuration);
export const originsApi = new OriginsApi(configuration);
export const projectsApi = new ProjectsApi(configuration);
export const settingsApi = new SettingsApi(configuration);
export const testAccountApi = new TestAccountApi(configuration);
export const tokensApi = new TokensApi(configuration);
export const usersApi = new UsersApi(configuration);
export const visitsApi = new VisitsApi(configuration);
export const webhooksApi = new WebhooksApi(configuration);
