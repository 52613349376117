/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FundingExternalWallets,
    FundingExternalWalletsFromJSON,
    FundingExternalWalletsFromJSONTyped,
    FundingExternalWalletsToJSON,
} from './FundingExternalWallets';
import {
    OnrampProviders,
    OnrampProvidersFromJSON,
    OnrampProvidersFromJSONTyped,
    OnrampProvidersToJSON,
} from './OnrampProviders';

/**
 * 
 * @export
 * @interface Funding
 */
export interface Funding {
    /**
     * 
     * @type {Array<OnrampProviders>}
     * @memberof Funding
     */
    onramps: Array<OnrampProviders>;
    /**
     * 
     * @type {FundingExternalWallets}
     * @memberof Funding
     */
    externalWallets?: FundingExternalWallets;
}

export function FundingFromJSON(json: any): Funding {
    return FundingFromJSONTyped(json, false);
}

export function FundingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Funding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onramps': ((json['onramps'] as Array<any>).map(OnrampProvidersFromJSON)),
        'externalWallets': !exists(json, 'externalWallets') ? undefined : FundingExternalWalletsFromJSON(json['externalWallets']),
    };
}

export function FundingToJSON(value?: Funding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onramps': ((value.onramps as Array<any>).map(OnrampProvidersToJSON)),
        'externalWallets': FundingExternalWalletsToJSON(value.externalWallets),
    };
}

