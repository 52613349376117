import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { TimeUnitEnum } from '@dynamic-labs/sdk-api';
import { Banner, Button, Typography } from '@dynamic-labs/northstar';

import { TimeTypeDropDown } from '../../TimeTypeDropDown';
import { useJwtSettings } from '../../../../../app/context/SettingsContext/helpers/jwtSettings';
import { TimeInput } from '../../TimeInput';

import styles from './JwtExpirationSidePage.module.scss';

const DEFAULT_AMOUNT = 2;
const DEFAULT_UNIT = TimeUnitEnum.Hours;
const JWT_TIME_UNITS = [TimeUnitEnum.Hours, TimeUnitEnum.Days];

export const JwtExpirationSidePage = () => {
  const { t } = useTranslation();

  const { settings, setJwtSettings, saveJwtSettings, settingsHasChanged } =
    useJwtSettings();

  const [amount, setAmount] = useState<number | ''>(
    settings?.jwtDuration?.amount ?? DEFAULT_AMOUNT,
  );

  const [showBanner, setShowBanner] = useState(false);

  const [unit, setUnit] = useState<TimeUnitEnum>(
    settings?.jwtDuration?.unit ?? DEFAULT_UNIT,
  );

  useEffect(() => {
    setAmount(settings?.jwtDuration?.amount ?? DEFAULT_AMOUNT);
    setUnit(settings?.jwtDuration?.unit ?? DEFAULT_UNIT);
  }, [settings?.jwtDuration?.amount, settings?.jwtDuration?.unit]);

  useEffect(() => {
    setJwtSettings({
      amount: amount === '' ? DEFAULT_AMOUNT : amount,
      unit: unit as TimeUnitEnum,
    });
  }, [amount, unit]);

  const handleSave = async () => {
    await saveJwtSettings();
    setShowBanner(true);
    setTimeout(() => setShowBanner(false), 3000);
  };
  return (
    <div>
      <Typography
        className={styles.heading}
        as='h1'
        variant='paragraph-2'
        weight='bold'
        color='black-2'
      >
        {t('v2.page.security.jwt_expiration_row.side_drawer.heading')}
      </Typography>
      <div className={styles.container}>
        <TimeInput
          amount={amount}
          setAmount={setAmount}
          unit={unit}
          className={styles.input}
        />
        <TimeTypeDropDown
          unit={unit}
          setUnit={setUnit}
          values={JWT_TIME_UNITS}
          className={styles.dropdown}
        />
        <Button
          onClick={handleSave}
          variant='primary'
          text={t('v2.page.security.jwt_expiration_row.side_drawer.button')}
          className={styles.primaryButton}
          disabled={!settingsHasChanged || amount === ''}
        />
      </div>
      <div className={styles.banner} />
      <AnimatePresence>
        {showBanner && (
          <Banner
            text={t('v2.page.security.jwt_expiration_row.side_drawer.banner')}
            variant='success'
            handleClose={() => setShowBanner(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
