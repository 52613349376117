import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TimeUnitEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../../../context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../context/SettingsContext';
import {
  MAX_VALUES_PER_UNIT,
  TimeInput,
} from '../../../../../../../appV2/routes/Security/TimeInput';
import { TimeTypeDropDown } from '../../../../../../../appV2/routes/Security/TimeTypeDropDown';
import { Typography } from '../../../../../../components/Typography';

const DEFAULT_AMOUNT = 30;
const DEFAULT_UNIT = TimeUnitEnum.Minutes;
const AVAILABLE_UNITS = [TimeUnitEnum.Minutes, TimeUnitEnum.Hours];

type Props = {
  onToggleChange: (value: boolean) => void;
};

export const SessionKeyLengthDropdown = ({ onToggleChange }: Props) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings } = useSettingsContext();

  const savedSessionKeyValue =
    settings[activeEnvironmentType]?.sdk.embeddedWallets?.sessionKeyDuration;

  const isEmailAuthEnabled =
    settings[activeEnvironmentType]?.sdk.embeddedWallets
      ?.supportedSecurityMethods?.email?.isEnabled;

  const [amountForInput, setAmountForInput] = useState<number | ''>(
    savedSessionKeyValue?.amount || DEFAULT_AMOUNT,
  );

  const unitForDropdown = savedSessionKeyValue?.unit || DEFAULT_UNIT;

  const handleChange = (amount: number | '', unit: TimeUnitEnum) => {
    // If the amount is empty, set values to default ones
    const unitToSave = amount === '' ? TimeUnitEnum.Minutes : unit;
    const amountToSave = amount === '' ? DEFAULT_AMOUNT : amount;

    // Don't update if the values are the same
    if (
      amountToSave === savedSessionKeyValue?.amount &&
      unitToSave === savedSessionKeyValue?.unit
    ) {
      return;
    }

    const updatedSettings = {
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType]?.sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            sessionKeyDuration: {
              amount:
                // if amount is bigger than the max value for the unit, set to max value (i.e. 30h)
                amountToSave > MAX_VALUES_PER_UNIT[unitToSave]
                  ? MAX_VALUES_PER_UNIT[unitToSave]
                  : amountToSave,
              unit: unitToSave,
            },
          },
        },
      },
    };

    onToggleChange(true);
    setSettings(updatedSettings);
  };

  // Update the amount when cancelling the changes
  useEffect(() => {
    setAmountForInput(savedSessionKeyValue?.amount || DEFAULT_AMOUNT);
  }, [savedSessionKeyValue?.amount]);

  return (
    <div>
      <div className='flex flex-col'>
        <Typography variant='paragraph-3' weight='medium'>
          {t('integrations.wallets.providers.turnkey.session_length.title')}
        </Typography>
        <Typography variant='paragraph-1' weight='medium' color='gray-2'>
          {t('integrations.wallets.providers.turnkey.session_length.info')}
        </Typography>
      </div>
      <div className='flex gap-2 mt-2.5'>
        <TimeInput
          amount={amountForInput}
          setAmount={setAmountForInput}
          unit={unitForDropdown}
          onBlur={() => handleChange(amountForInput, unitForDropdown)}
          disabled={!isEmailAuthEnabled}
          disabledTooltipText={[
            t('integrations.wallets.providers.turnkey.session_length.disabled'),
          ]}
        />
        <TimeTypeDropDown
          unit={unitForDropdown}
          setUnit={(value) => handleChange(amountForInput, value)}
          values={AVAILABLE_UNITS}
          disabled={!isEmailAuthEnabled}
          disabledTooltipText={[
            t('integrations.wallets.providers.turnkey.session_length.disabled'),
          ]}
        />
      </div>
    </div>
  );
};
