import type { FC } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { PageHeader, SaveBanner } from '../../components';

import { SecurityIntegrationsRow } from './SecurityIntegrationsRow';
import { IpSettingsRow } from './IpSettingsRow';
import { useSaveSecuritySettings } from './useSaveSecuritySettings';
import styles from './FraudPrevention.module.scss';

export const FraudPrevention: FC = () => {
  const { t } = useTranslation();
  const { handleReset, handleSave, isLoading, shouldShowSaveBanner } =
    useSaveSecuritySettings();

  return (
    <section className={styles.wrapper}>
      <PageHeader
        title={t('v2.page.fraud_prevention.title')}
        description={t('v2.page.fraud_prevention.description')}
      />
      <div className={styles.content}>
        <SecurityIntegrationsRow />
        <IpSettingsRow />
      </div>
      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnSave={handleSave}
            handleOnCancel={handleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default FraudPrevention;
