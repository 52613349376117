/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EcdsaValidatorOptions,
    EcdsaValidatorOptionsFromJSON,
    EcdsaValidatorOptionsFromJSONTyped,
    EcdsaValidatorOptionsToJSON,
} from './EcdsaValidatorOptions';
import {
    ProviderAgreement,
    ProviderAgreementFromJSON,
    ProviderAgreementFromJSONTyped,
    ProviderAgreementToJSON,
} from './ProviderAgreement';
import {
    ProviderEntryPointVersionEnum,
    ProviderEntryPointVersionEnumFromJSON,
    ProviderEntryPointVersionEnumFromJSONTyped,
    ProviderEntryPointVersionEnumToJSON,
} from './ProviderEntryPointVersionEnum';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';
import {
    ProviderKernelVersionEnum,
    ProviderKernelVersionEnumFromJSON,
    ProviderKernelVersionEnumFromJSONTyped,
    ProviderKernelVersionEnumToJSON,
} from './ProviderKernelVersionEnum';
import {
    ProviderMultichainAccountAbstractionProviders,
    ProviderMultichainAccountAbstractionProvidersFromJSON,
    ProviderMultichainAccountAbstractionProvidersFromJSONTyped,
    ProviderMultichainAccountAbstractionProvidersToJSON,
} from './ProviderMultichainAccountAbstractionProviders';
import {
    SmsCountryCode,
    SmsCountryCodeFromJSON,
    SmsCountryCodeFromJSONTyped,
    SmsCountryCodeToJSON,
} from './SmsCountryCode';

/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    id?: string;
    /**
     * 
     * @type {ProviderEnum}
     * @memberof Provider
     */
    provider: ProviderEnum;
    /**
     * 
     * @type {Date}
     * @memberof Provider
     */
    enabledAt?: Date | null;
    /**
     * Standard OAuth client ID. For more information, see: https://www.oauth.com/oauth2-servers/client-registration/client-id-secret/
     * @type {string}
     * @memberof Provider
     */
    clientId?: string;
    /**
     * Standard OAuth client secret key. For more information, see: https://www.oauth.com/oauth2-servers/client-registration/client-id-secret/
     * @type {string}
     * @memberof Provider
     */
    clientSecret?: string;
    /**
     * Some providers require additional information, typically called a project ID or site ID
     * @type {string}
     * @memberof Provider
     */
    providerProjectId?: string;
    /**
     * If the provider supports Oauth 2, this field will contain the URL of the login and authorization where a user can authorize the applciation to gain access to their provider account
     * @type {string}
     * @memberof Provider
     */
    authorizationUrl?: string;
    /**
     * If the provider supports Oauth 2, this field will contain the URL of redirect or callback URL which will need to be provided to the Oauth provider to properly configure your App tp talk with Dynamic
     * @type {string}
     * @memberof Provider
     */
    redirectUrl?: string;
    /**
     * DEPRECATED: Use defaultChain. The default chain id the SDK should use
     * @type {number}
     * @memberof Provider
     */
    defaultChainId?: number;
    /**
     * CAIP-2 Chain ID (https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-2.md)
     * @type {string}
     * @memberof Provider
     */
    defaultChain?: string;
    /**
     * The url of the site to go to export wallets private keys
     * @type {string}
     * @memberof Provider
     */
    keyExportUrl?: string;
    /**
     * 
     * @type {ProviderAgreement}
     * @memberof Provider
     */
    termsAcceptedByUser?: ProviderAgreement;
    /**
     * Optional custom space-delimited list of Oauth scopes for the social provider
     * @type {string}
     * @memberof Provider
     */
    scopes?: string;
    /**
     * Base auth url for oauth provider
     * @type {string}
     * @memberof Provider
     */
    baseAuthUrl?: string;
    /**
     * Key ID required for Apple Oauth2 applications. This is the identifier for a private key.
     * @type {string}
     * @memberof Provider
     */
    appleKeyId?: string;
    /**
     * Team ID required for Apple Oauth2 applications. This is associated with the Apple developer membership account.
     * @type {string}
     * @memberof Provider
     */
    appleTeamId?: string;
    /**
     * Shopify store required for Shopify Oauth2 applications.
     * @type {string}
     * @memberof Provider
     */
    shopifyStore?: string;
    /**
     * Required to initialize Twilio provider.
     * @type {string}
     * @memberof Provider
     */
    accountSid?: string;
    /**
     * Required to initialize Twilio provider. Phone number used to send SMS messages.
     * @type {string}
     * @memberof Provider
     */
    twilioNumber?: string;
    /**
     * 
     * @type {Array<SmsCountryCode>}
     * @memberof Provider
     */
    enabledCountries?: Array<SmsCountryCode>;
    /**
     * 
     * @type {ProviderEntryPointVersionEnum}
     * @memberof Provider
     */
    entryPointVersion?: ProviderEntryPointVersionEnum;
    /**
     * 
     * @type {ProviderKernelVersionEnum}
     * @memberof Provider
     */
    kernelVersion?: ProviderKernelVersionEnum;
    /**
     * 
     * @type {Array<ProviderMultichainAccountAbstractionProviders>}
     * @memberof Provider
     */
    multichainAccountAbstractionProviders?: Array<ProviderMultichainAccountAbstractionProviders>;
    /**
     * 
     * @type {EcdsaValidatorOptions}
     * @memberof Provider
     */
    ecdsaProviderType?: EcdsaValidatorOptions;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    createNewAccounts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    enableKernelV3Migration?: boolean;
}

export function ProviderFromJSON(json: any): Provider {
    return ProviderFromJSONTyped(json, false);
}

export function ProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Provider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': ProviderEnumFromJSON(json['provider']),
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (json['enabledAt'] === null ? null : new Date(json['enabledAt'])),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'providerProjectId': !exists(json, 'providerProjectId') ? undefined : json['providerProjectId'],
        'authorizationUrl': !exists(json, 'authorizationUrl') ? undefined : json['authorizationUrl'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'defaultChainId': !exists(json, 'defaultChainId') ? undefined : json['defaultChainId'],
        'defaultChain': !exists(json, 'defaultChain') ? undefined : json['defaultChain'],
        'keyExportUrl': !exists(json, 'keyExportUrl') ? undefined : json['keyExportUrl'],
        'termsAcceptedByUser': !exists(json, 'termsAcceptedByUser') ? undefined : ProviderAgreementFromJSON(json['termsAcceptedByUser']),
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
        'baseAuthUrl': !exists(json, 'baseAuthUrl') ? undefined : json['baseAuthUrl'],
        'appleKeyId': !exists(json, 'appleKeyId') ? undefined : json['appleKeyId'],
        'appleTeamId': !exists(json, 'appleTeamId') ? undefined : json['appleTeamId'],
        'shopifyStore': !exists(json, 'shopifyStore') ? undefined : json['shopifyStore'],
        'accountSid': !exists(json, 'accountSid') ? undefined : json['accountSid'],
        'twilioNumber': !exists(json, 'twilioNumber') ? undefined : json['twilioNumber'],
        'enabledCountries': !exists(json, 'enabledCountries') ? undefined : ((json['enabledCountries'] as Array<any>).map(SmsCountryCodeFromJSON)),
        'entryPointVersion': !exists(json, 'entryPointVersion') ? undefined : ProviderEntryPointVersionEnumFromJSON(json['entryPointVersion']),
        'kernelVersion': !exists(json, 'kernelVersion') ? undefined : ProviderKernelVersionEnumFromJSON(json['kernelVersion']),
        'multichainAccountAbstractionProviders': !exists(json, 'multichainAccountAbstractionProviders') ? undefined : ((json['multichainAccountAbstractionProviders'] as Array<any>).map(ProviderMultichainAccountAbstractionProvidersFromJSON)),
        'ecdsaProviderType': !exists(json, 'ecdsaProviderType') ? undefined : EcdsaValidatorOptionsFromJSON(json['ecdsaProviderType']),
        'createNewAccounts': !exists(json, 'createNewAccounts') ? undefined : json['createNewAccounts'],
        'enableKernelV3Migration': !exists(json, 'enableKernelV3Migration') ? undefined : json['enableKernelV3Migration'],
    };
}

export function ProviderToJSON(value?: Provider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'provider': ProviderEnumToJSON(value.provider),
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt === null ? null : value.enabledAt.toISOString()),
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'providerProjectId': value.providerProjectId,
        'authorizationUrl': value.authorizationUrl,
        'redirectUrl': value.redirectUrl,
        'defaultChainId': value.defaultChainId,
        'defaultChain': value.defaultChain,
        'keyExportUrl': value.keyExportUrl,
        'termsAcceptedByUser': ProviderAgreementToJSON(value.termsAcceptedByUser),
        'scopes': value.scopes,
        'baseAuthUrl': value.baseAuthUrl,
        'appleKeyId': value.appleKeyId,
        'appleTeamId': value.appleTeamId,
        'shopifyStore': value.shopifyStore,
        'accountSid': value.accountSid,
        'twilioNumber': value.twilioNumber,
        'enabledCountries': value.enabledCountries === undefined ? undefined : ((value.enabledCountries as Array<any>).map(SmsCountryCodeToJSON)),
        'entryPointVersion': ProviderEntryPointVersionEnumToJSON(value.entryPointVersion),
        'kernelVersion': ProviderKernelVersionEnumToJSON(value.kernelVersion),
        'multichainAccountAbstractionProviders': value.multichainAccountAbstractionProviders === undefined ? undefined : ((value.multichainAccountAbstractionProviders as Array<any>).map(ProviderMultichainAccountAbstractionProvidersToJSON)),
        'ecdsaProviderType': EcdsaValidatorOptionsToJSON(value.ecdsaProviderType),
        'createNewAccounts': value.createNewAccounts,
        'enableKernelV3Migration': value.enableKernelV3Migration,
    };
}

