import type { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';

export const IpSettingsRow: FC = () => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const handleIpCollectionToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [activeEnvironmentType]: {
        ...prevSettings[activeEnvironmentType],
        privacy: {
          ...prevSettings[activeEnvironmentType].privacy,
          collectIp: event.target.checked,
        },
      },
    }));
  };

  const isIpCollectionEnabled = Boolean(
    settings[activeEnvironmentType]?.privacy?.collectIp,
  );

  const isIpCollectionSaved = Boolean(
    settings[activeEnvironmentType]?.privacy?.collectIp ===
      initialSettings[activeEnvironmentType]?.privacy?.collectIp,
  );

  return (
    <Section
      title={t('v2.page.fraud_prevention.ip_settings.title')}
      description={t('v2.page.fraud_prevention.ip_settings.description')}
    >
      <SingleToggleCard
        accordionKey='ip-collection'
        title={t('v2.page.fraud_prevention.ip_settings.save_ip.title')}
        description={t(
          'v2.page.fraud_prevention.ip_settings.save_ip.description',
        )}
        allowExpand={false}
        inputProps={{
          checked: isIpCollectionEnabled,
          id: 'ip-collection-toggle',
          isSaved: isIpCollectionSaved,
          onChange: handleIpCollectionToggle,
          type: 'toggle',
        }}
      />
    </Section>
  );
};
