/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {number}
     * @memberof Network
     */
    networkId: number;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    chainName: string;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    rpcUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    iconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    type?: NetworkTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum NetworkTypeEnum {
    Custom = 'custom',
    Default = 'default'
}

export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'networkId': json['networkId'],
        'chainName': json['chainName'],
        'enabled': json['enabled'],
        'rpcUrl': !exists(json, 'rpcUrl') ? undefined : json['rpcUrl'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function NetworkToJSON(value?: Network | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'networkId': value.networkId,
        'chainName': value.chainName,
        'enabled': value.enabled,
        'rpcUrl': value.rpcUrl,
        'iconUrl': value.iconUrl,
        'type': value.type,
    };
}

