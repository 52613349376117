/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecoverKeyshareResponse
 */
export interface RecoverKeyshareResponse {
    /**
     * 
     * @type {string}
     * @memberof RecoverKeyshareResponse
     */
    encryptedKeyShare: string;
}

export function RecoverKeyshareResponseFromJSON(json: any): RecoverKeyshareResponse {
    return RecoverKeyshareResponseFromJSONTyped(json, false);
}

export function RecoverKeyshareResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecoverKeyshareResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'encryptedKeyShare': json['encryptedKeyShare'],
    };
}

export function RecoverKeyshareResponseToJSON(value?: RecoverKeyshareResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'encryptedKeyShare': value.encryptedKeyShare,
    };
}

